import { MDBContainer, MDBInput } from 'mdb-react-ui-kit'
import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import DropDown from './forms/DropDown'
import "./form.css"
import { db } from './firebase'
import DataFetech from './dataFetech'
import { form_data } from './Menu-data'
import TemplateFetch from './TemplateFetch'
import { Height } from '@mui/icons-material'
import LoaderComp from './LoaderComp'; // Import your Loader component


export default class VehicleReportModify extends Component {

  constructor(props) {
    super(props);
    this.state = { templates: [], form: "Master/Bank", isLoading: false, data: [], fieldVals: [], rowNum: 2 };
    this.handleTempName = this.handleTempName.bind(this);
  }

  getTemplateData = (i) => {

    let arr = [];
    i.forEach(j => {
      if (j.category == window.location.pathname.split("/")[2].split("%20").join(" "))
        arr.push(j.name)
    });

    this.setState({ templates: arr, data: i, });
    let formm = document.querySelector(".templateName").value;
  }

  handleSave = (i) => {
    // console.log(i);  
    // formm = form_data[formm[0]][formm[1]].map(i => i.title);
    // console.log(formm);

    // return;
    let { data } = this.state;
    let obj = {}
    let idx;
    document.querySelectorAll(".field").forEach(i => {
      obj[i.children[1].value] = i.children[0].value;
    })
    if(Object.keys(obj) == ""){
      return null
     }else{
    this.setState({ isLoading: true });
    data.forEach((i, j) => {
      if (document.querySelector(".templateName").value == i.name) {
        idx = j;
        i.fields = obj;
        return
      }
    })

    db.collection("templates").doc(data[idx].id).update({ data: obj }).then(() => {
      this.setState({ isLoading: false });
      window.location.reload(false);
    });
  }
    // this.setState({ isLoading: false });
  }

  handleTempOption = (e) => {
    console.log(e.target.value);
  }


  handleTempName = () => {
    let { fieldVals } = this.state;
    let rayData = {};
    let formm = document.querySelector(".templateName").value;
    if(formm == "Select a template"){
      return null
    }else{
    
      this.state.data.map((i)=>{

        if(i == undefined){
          return null
        }else{
          // console.log(i);
          if(i.category == "Vehicle Valuation"){
            // console.log(i,formm); 
            if(i.name == formm){
              console.log(i.format);
              const myDiv = document.getElementById("showFormate");
                        myDiv.innerHTML = i.format;
            }
  
          }
        }
        
  
      })







    this.state.data.forEach(j => {
      if (j.name == formm)
        formm = j.category;
    })
    fieldVals = form_data[formm]["New Intimation"].map(i => i.title);
    rayData = fieldVals.push('numberplatepic', 'selfienumberplate', 'frontpic', 'frontrightpic', 'rightsidepic', 'rearrightpic', 'backside', 'rearleftpic', 'leftside', 'frontleft', 'erwn', 'erls', 'engineroomright', 'chasisnumber', 'enginechasis', 'dashboardpic', 'gearstick', 'odometerpic', 'frhdi', 'frontback', 'dicky', 'tfr', 'trr', 'tfl', 'trl', 'stepney', 'rcbook');
    setTimeout(() => {
      this.setState({ fieldVals })
    }, 500)

    
    

  }
  }

  handleGo = () => {
    console.log(this.state.templates, this.state.data, this.state.fieldVals);
    this.setState({ rowNum: +document.querySelector(".rowNum").value });
  }

  render() {
    // let dropdowns = for(let l = 0; l < 5;l++){console.log("hi")}
    // <div className={`${i} field`}>
    // <select>
    //     {this.state.fieldVals.map(i => {       
    //         return <option>{i}</option>
    //     }
    //     )
    // }
    // </select>
    // <input style={{marginLeft : "30%", marginBottom: "5%"}} type={'text'}></input>
    // </div>;


    return (
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-4 col-lg-4' style={{ borderTop: "2px solid", borderLeft: "2px solid", borderRight: "2px solid", height: "80vh", overflow: "auto" }}>
            <div className="p-3 my-5 d-flex flex-column " style={{ width: "100%" }}>
              {/* <DataFetech isTemplate={true} getTemplateData={this.getTemplateData}/> */}
              <TemplateFetch getTemplateData={this.getTemplateData} />
              <h1>Report Format</h1>
              <p></p>
              <h6>Report Account</h6>
              <h6>Format : <span id ="showFormate"> </span></h6>
              <select className='templateName' onChange={this.handleTempName}>
  <option>Select a template</option> {/* Default option */}
  {this.state.templates.map(i => {
    return <option key={i} value={i}>{i}</option>
  })}
</select>
              <p></p>
              <MDBInput className='rowNum' label="Number of Rows"></MDBInput>
              <p></p>
              <Button onClick={this.handleGo}>Go</Button>
              <p></p>
              {/* {[1,2,3].map(i => {
        return(
            
          )})
        } */}

            </div>
          </div>
          <div className='col-lg-8 col-mg-8' style={{ borderTop: "2px solid", borderLeft: "2px solid", borderRight: "2px solid" , height: "80vh", overflow: "auto" }}>
            <div className="p-3 my-5 d-flex flex-column " style={{ width: "80%" }}>
            <h1 className='mb-3'>Selecte No. Of Column</h1>
            <span className='text-start'>
            <h5>Default Column Name</h5>
            <h5 style={{marginLeft:"200px"}}>Column Name Of Report</h5>
            </span>
              {
                Array(this.state.rowNum).fill(Math.random()).map(i => {
                  return <div className={`${i} field`}>
                    <select>
                      {this.state.fieldVals.map(i => {
                        return <option>{i}</option>
                      }
                      )
                      }
                    </select>
                    <input style={{ marginLeft: "30%", marginBottom: "5%" }} type={'text'}></input>
                  </div>;
                })
              }
              <p></p>
              <span className='text-center'>
                <Button onClick={this.handleSave} style={{ width: "230px" }}>Save</Button>
              </span>
              <div>{this.state.isLoading && <LoaderComp />}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
