import * as React from 'react';
import { redirect, useNavigate, useParams } from 'react-router';
import { form_data } from './Menu-data';
import { Button } from '@mui/material';
import exportFunc from './Excelexport';
import Table from 'react-bootstrap/Table';
import { db } from './firebase'
import LoaderComp from './LoaderComp'; // Import your Loader component
import { RowingSharp } from '@mui/icons-material';
import "./DataTable.css"
import "./model.css"
import { storage } from "./firebase"


export default function DataTable(props) {
  let { formName } = useParams();
  const [showModal, setShowModal] = React.useState(false);
  const [idx, setidx] = React.useState();
  // const [idx, setidx] = React.useState();
  // const [startDate, setStartDate] = React.useState(null);
  // const [endDate, setEndDate] = React.useState(null);
  let currentPage = window.location.pathname.split("/");
  currentPage.forEach((i, j) => {
    if (i.indexOf("%20") > -1)
      currentPage[j] = i.split("%20").join(" ");
  });
  const currentURL = window.location.href;
  const isMasterpage = currentURL.includes('Master');
  const isPropertyPage = currentURL.includes('Single');
  const isVehiclePage = currentURL.includes('Group');
  let rows = [];
  rows = props.rows;
  // console.log(rows);
  const currentDate = new Date().toISOString().slice(0, 10);
  // console.log(currentDate)
  const [filteredRows, setFilteredRows] = React.useState(rows);
  const [imgDemo, setImg] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [imgDat, setImgData] = React.useState([]);
  // const [isLoading, setIsLoading] = useState(false);

  // Initialize the start date with the current date
  const [startDate, setStartDate] = React.useState(currentDate);
  // Initialize the end date with an empty string
  const [endDate, setEndDate] = React.useState(currentDate);

  function handleEdit(i) {
    // console.log(rows[i.target.id]);
    props.updateData(rows[i.target.id]);


  }
  if (currentPage[3] == "Mis") {
    // console.log(currentPage);
    let mdiv = document.getElementById("misDiv");
    if (mdiv) {
      mdiv.style.minWidth = "100%";
    } else {
      console.error("Element with id 'misDiv' not found.");
    }
  }


  function handleDelete(i) {
    props.removeData(rows[i.target.id]);
  }

  function retakeData(i) {
    props.dataa.map((p) => {
      if (p.field == "Vehicle Valuation/New Intimation" || p.field == "Vehicle Valuation/Inspection Done") {
        // console.log(p.id,rows[i.target.id]["id"]);
        if (p.id == rows[i.target.id]["id"]) {
          if (p.data["Model"] || p.data["Chasis Number"] || p.data["date"] || p.data["Engine Number"] || p.data["Odometer Reading"] || p.data["Color of Vehicle"] || p.data["Condition of Vehicle Body"] || p.data["Condition of Vehicle Engine"] || p.data["Condtion of Tyre"] || p.data["Remarks"]) {
            console.log("123");
            let Status = p.data["Status"] = "Fresh";
            let Chasis = p.data["Chasis Number"] = "";
            let Model = p.data["Model"] = "";
            let Engine = p.data["Engine Number"] = "";
            let Odometer = p.data["Odometer Reading"] = "";
            let ColorofVehicle = p.data["Color of Vehicle"] = "";
            let VehicleBody = p.data["Condition of Vehicle Body"] = "";
            let VehicleEngine = p.data["Condition of Vehicle Engine"] = "";
            let Tyre = p.data["Condtion of Tyre"] = "";
            let Remarks = p.data["Remarks"] = "";
            let image = p.images = "";
            let simg = p.imgStatus = "";
            let timeout = p.TimeOut = "No";
            p.data["date"] = "";

            const folderPath = `images/null/${p.id}`;
            const folderRef = storage.ref().child(folderPath);
            folderRef
              .listAll()
              .then((result) => {
                result.items.forEach((itemRef) => {
                  console.log('Item in folder:', itemRef.fullPath);
                  itemRef
                    .delete()
                    .then(() => {
                      // console.log('Image deleted successfully.');
                    })
                    .catch((error) => {
                      // console.error('Error deleting image:', error);
                    });

                });
              })
              .catch((error) => {
                // console.error('Error listing folder contents:', error);
              });


            setShowModal(!showModal);
            setidx(i.target.id);
            document.querySelectorAll("input")[0].value = p.data["Inspection Date"];
            document.querySelectorAll("input")[1].value = p.data["Intimation Time"];
            db.collection("mess").doc(rows[i.target.id]["id"]).update(p);



          }
        }
      }
      else if (p.field == "Property Valuation/New Intimation" || p.field == "Property Valuation/Inspection Done") {
        if (p.id == rows[i.target.id]["id"]) {
          // console.log(p.data["Area of Construction"]["4F"]);
          if (p.data["bankname"] || p.data["landmark"] || p.data["wardnumber"] || p.data["usage"] || p.data["occupancy"] || p.data["listofoccupants"] || p.data["northboundary"] || p.data["eastboundary"] || p.data["southboundary"] || p.data["westboundary"] || p.data["shape"] || p.data["locality"] || p.data["areawidthf"] || p.data["areawidthr"] || p.data["arealengthl"] || p.data["arealengthr"] || p.data["totalarea"] || p.data["numberoffloors"] || p.data["numberoflifts"] || p.data["ageofproperty"] || p.data["road"] || p.data["roadwidth"] || p.data["propertyrate"] || p.data["brokername"] || p.data["brokernumber"] || p.data["propertydemarcation"] || p.data["rescom"] || p.data["unitareabup"] || p.data["unitareacarpet"] || p.data["unitareasbup"]) {
            p.data["bankname"] = "";
            p.data["date"] = "";
            p.data["landmark"] = "";
            p.data["wardnumber"] = "";
            p.data["usage"] = "";
            p.data["occupancy"] = "";
            p.data["listofoccupants"] = "";
            p.data["northboundary"] = "";
            p.data["eastboundary"] = "";
            p.data["southboundary"] = "";
            p.data["westboundary"] = "";
            p.data["shape"] = "";
            p.data["locality"] = "";
            p.data["areawidthf"] = "";
            p.data["areawidthr"] = "";
            p.data["arealengthl"] = "";
            p.data["arealengthr"] = "";
            p.data["totalarea"] = "";
            p.data["numberoffloors"] = "";
            p.data["numberoflifts"] = "";
            p.data["ageofproperty"] = "";
            p.data["road"] = "";
            p.data["roadwidth"] = "";
            p.data["propertyrate"] = "";
            p.data["brokername"] = "";
            p.data["brokernumber"] = "";
            p.data["propertydemarcation"] = "";
            p.data["rescom"] = "";
            p.data["unitareabup"] = "";
            p.data["unitareacarpet"] = "";
            p.data["unitareasbup"] = "";
            p.data["Area of Construction"]["4F"]["numberofKitchen"] = "";
            p.data["Area of Construction"]["4F"]["numberofRoom"] = "";
            p.data["Area of Construction"]["4F"]["numberofToilet"] = "";
            p.data["Area of Construction"]["4F"]["numberofhall"] = "";
            p.data["Area of Construction"]["4F"]["totalArea"] = "";
            p.data["Area of Construction"]["5F"]["numberofKitchen"] = "";
            p.data["Area of Construction"]["5F"]["numberofRoom"] = "";
            p.data["Area of Construction"]["5F"]["numberofToilet"] = "";
            p.data["Area of Construction"]["5F"]["numberofhall"] = "";
            p.data["Area of Construction"]["5F"]["totalArea"] = "";
            p.data["Area of Construction"]["BF"]["numberofKitchen"] = "";
            p.data["Area of Construction"]["BF"]["numberofRoom"] = "";
            p.data["Area of Construction"]["BF"]["numberofToilet"] = "";
            p.data["Area of Construction"]["BF"]["numberofhall"] = "";
            p.data["Area of Construction"]["BF"]["totalArea"] = "";
            p.data["Area of Construction"]["FF"]["numberofKitchen"] = "";
            p.data["Area of Construction"]["FF"]["numberofRoom"] = "";
            p.data["Area of Construction"]["FF"]["numberofToilet"] = "";
            p.data["Area of Construction"]["FF"]["numberofhall"] = "";
            p.data["Area of Construction"]["FF"]["totalArea"] = "";
            p.data["Area of Construction"]["GF"]["numberofKitchen"] = "";
            p.data["Area of Construction"]["GF"]["numberofRoom"] = "";
            p.data["Area of Construction"]["GF"]["numberofToilet"] = "";
            p.data["Area of Construction"]["GF"]["numberofhall"] = "";
            p.data["Area of Construction"]["GF"]["totalArea"] = "";
            p.data["Area of Construction"]["MZ"]["numberofKitchen"] = "";
            p.data["Area of Construction"]["MZ"]["numberofRoom"] = "";
            p.data["Area of Construction"]["MZ"]["numberofToilet"] = "";
            p.data["Area of Construction"]["MZ"]["numberofhall"] = "";
            p.data["Area of Construction"]["MZ"]["totalArea"] = "";
            p.data["Area of Construction"]["SF"]["numberofKitchen"] = "";
            p.data["Area of Construction"]["SF"]["numberofRoom"] = "";
            p.data["Area of Construction"]["SF"]["numberofToilet"] = "";
            p.data["Area of Construction"]["SF"]["numberofhall"] = "";
            p.data["Area of Construction"]["SF"]["totalArea"] = "";
            p.data["Area of Construction"]["TF"]["numberofKitchen"] = "";
            p.data["Area of Construction"]["TF"]["numberofRoom"] = "";
            p.data["Area of Construction"]["TF"]["numberofToilet"] = "";
            p.data["Area of Construction"]["TF"]["numberofhall"] = "";
            p.data["Area of Construction"]["TF"]["totalArea"] = "";
            p.images = "";
            p.imgStatus = "";
            p.TimeOut = "No";
            p.data["Status"] = "Fresh";
            const folderPath = `images/null/${p.id}`;
            const folderRef = storage.ref().child(folderPath);
            folderRef
              .listAll()
              .then((result) => {
                result.items.forEach((itemRef) => {
                  console.log('Item in folder:', itemRef.fullPath);
                  itemRef
                    .delete()
                    .then(() => {
                      // console.log('Image deleted successfully.');
                    })
                    .catch((error) => {
                      // console.error('Error deleting image:', error);
                    });

                });
              })
              .catch((error) => {
                // console.error('Error listing folder contents:', error);
              });
            setShowModal(!showModal);
            setidx(i.target.id);
            document.querySelectorAll("input")[0].value = p.data["Inspection Date"];
            document.querySelectorAll("input")[1].value = p.data["Intimation Time"];
            db.collection("mess").doc(rows[i.target.id]["id"]).update(p);
          }
        }
      }
    })
  }

  function handleExport(e) {
    let exportData = props.rows;
    exportData.forEach(i => delete i.id);
    // console.log(exportData);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleFilter = () => {
    if (startDate && endDate) {
      let filteredData = rows.filter((row) => {
        // const rowDate = new Date(row["Inspection Date"]); // Change 'date' to your date field name
        // return rowDate >= new Date(startDate) && rowDate <= new Date(endDate);
        const rowDate = new Date(row["Current Date"]); // Ensure 'date' field matches the date format
        const filterStartDate = new Date(startDate);
        const filterEndDate = new Date(endDate);

        return rowDate >= filterStartDate && rowDate <= filterEndDate;
      });
      setFilteredRows(filteredData);
    }
  };
  const handleFilteName = (event) => {
    event.preventDefault(); // Prevents the default form submission behavior

    const searchTerm = document.getElementById("searchInput").value.toLowerCase();
    console.log(searchTerm);

    if (searchTerm) {
      let filteredData = rows.filter((row) => {
        // Assuming the name is stored in a property named "Name"
        // console.log(row["Customer Name"]);
        const rowName = row["Customer Name"].toLowerCase();
        return rowName.includes(searchTerm);
      });
      setFilteredRows(filteredData);
    }
  };

  const handleFilteRefNo = (event) => {
    event.preventDefault(); // Prevents the default form submission behavior

    const searchTerm = document.getElementById("searchInputa").value.toLowerCase();
    if (searchTerm) {
      let filteredData = rows.filter((row) => {
        // Assuming the name is stored in a property named "Name"
        console.log(row["Ref. No."]);
        const rowName = row["Ref. No."].toLowerCase();
        return rowName.includes(searchTerm);
      });
      setFilteredRows(filteredData);
    }
  };


  // const handleFilteRefNo = (event) => {
  //   event.preventDefault(); // Prevents the default form submission behavior

  //   const searchTerm = document.getElementById("searchInputa").value.trim().toLowerCase();
  //   console.log(searchTerm);
  //   if (searchTerm) {
  //     let filteredData = rows.filter((row) => {
  //       // Assuming the reference number is stored in a property named "Ref. No."
  //       const rowRefNo = row["Ref. No."].toLowerCase();
  //       console.log(rowRefNo,searchTerm);
  //       return rowRefNo == searchTerm;
  //     });
  //     setFilteredRows(filteredData);
  //   }
  // };

  const handleUpdate = () => {
    console.log(rows[idx]);
    let updated = rows[idx];
    updated["Inspection Date"] = document.getElementById("datemodel").value;
    updated["Inspection Time"] = document.getElementById("timemodel").value;
    // setIsLoading(true);
    db.collection("mess").doc(updated.id).update({ data: updated }).then(() => {
      setShowModal(!showModal);
    });
    // 
  }

  React.useEffect(() => {
    // Initialize filteredRows with the initial data when the component mounts
    if (currentPage[2] == "Property Valuation" || currentPage[2] == "Vehicle Valuation") {
      if (currentPage[3] == "Current Date") {
        let ar = [];
        let filteredData = rows.filter((row) => {
          if (row["Status"] == "Current Date") {
            ar.push(row)
          }
        });
        setFilteredRows(ar);
      } else if (currentPage[3] == "Mis") {
        let mdiv = document.getElementById("misDiv");
        mdiv.style.width = "99%";
        let ar = [];
        let filteredData = rows.filter((row) => {
          if (row["Current Date"] == currentDate) {
            ar.push(row)
          }
        });
        setFilteredRows(ar);
      }
      else {
        let ar = [];
        let filteredData = rows.filter((row) => {
          if (row["Current Date"] == currentDate) {
            ar.push(row)
          }
        });
        setFilteredRows(ar);
      }
    }
    else {
      setFilteredRows(rows);
    }

  }, [rows]);

  function handleClick(id, title) {
    // console.log("Clicked ID:", id);
    // console.log("Clicked Title:", title);
    // console.log(filteredRows);
    filteredRows.map((i) => {
      if (i.id == id) {
        JSON.parse(i.data1).map((k) => {
          console.log(k);
          if (k[title] !== undefined) {
            // console.log(typeof k[title]);
            if (typeof (k[title]) == "object") {
              let imgdata = []
              k[title].forEach((j) => {
                imgdata.push(j)
              });

              setImgData(imgdata);
              setShowModal(!showModal);

            } else {
              return null;
            }
          }

        })


      }
    })




  }

  console.log(imgDat);

  return (
    <div className='col-md-8 col-lg-8 display' id='misDiv'
      // style={(form_data[currentPage[2]][currentPage[3]].length > 5) ? { display:"none" } : {  borderTop: "2px solid", borderLeft: "2px solid", borderRight: "2px solid", height: "80vh", overflow: "auto"  }}
      style={{ borderTop: "2px solid", borderLeft: "2px solid", borderRight: "2px solid", height: "80vh", overflow: "auto" }}>
      <div>
        {isMasterpage && (
          <div className='text-center mt-3'>
            <h1>Data Of Banks</h1>
          </div>
        )}
        {isPropertyPage && (
          <div className='mt-3'>
            <h1 className='text-center'>Data of Single JLG</h1>
            {/* <div>
                <div>
                  <form>
                    <label htmlFor="searchInput">Search by Customer Name:</label>
                    <input type="text" id="searchInput" name="searchInput" />
                    <button className='filterbtn' onClick={(e) => handleFilteName(e)} style={{ marginLeft: "20px" }}>Apply Filter</button>
                    <br></br>
                    <label htmlFor="searchInputa" className='mt-3'>Search by Ref. No.:</label>
                    <input type="text" id="searchInputa" name="searchInputa" />
                    <button className='filterbtn' onClick={(e) => handleFilteRefNo(e)} style={{ marginLeft: "20px" }}>Apply Filter</button>
                  </form>
                </div>

                <div className='text-end'>
                  Start Date:
                  <input className='me-3' type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                  End Date:
                  <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                </div>
                <div className='text-end mt-3 mb-3'>
                  <button className='filterbtn' onClick={handleFilter}>Apply Filter</button>
                </div>
              </div> */}
          </div>
        )}
        {isVehiclePage && (
          <div className='mt-3'>
            <h1 className='text-center'>Data of Group JLG</h1>
           
          </div>
        )}
        {/* <div class="form" style={{display : 'none'}}>
      <div  className="p-3 my-5 d-flex flex-column w-50">
          <input wrapperClass='mb-4' min="0" label="Intimation Date" className='form1' type="date" autoFill={false}/>
          <input wrapperClass='mb-4' min="0" label="Intimation Time" className='form1' type="time" autoFill={false}/>
          <input key="mb-4 w-100" className="mb-4 w-100" onClick={handleUpdate}>Update</input> 
      </div>
      </div> */}

        {showModal && (
          <div className="modalrender">
            <div className="modal-content">
              <span className="close" onClick={toggleModal} style={{ textAlign: "end" }}>
                &times;
              </span>
              <div className="">
                <div id='dataImagSrc'>
                  {imgDat.map((i) => {
                    return <img src={i} width="400" height="400" style={{ margin: "20px 20px" }}></img>
                    // const paragraph = document.getElementById('dataImagSrc');
                    // const img = document.createElement('img');
                    // img.src = i;
                    // paragraph.appendChild(img);
                  }


                  )}
                </div>
              </div>
            </div>
          </div>
        )}



        <Table striped bordered hover responsive border={"1px solid black"} id='bankTable'>

          <thead>
            <tr>
              <th component="th" scope="row">{"Sr."}</th>
              {form_data[currentPage[2]][currentPage[3]].map(i => {
                return <th component="th" scope="row">{i.title}</th>
              })}
               {currentPage[3] == "Mis" ? null :   <th component="th" scope="row">Action(Edit)</th>}
             
              <th component="th" scope="row">Action(Delete)</th>
              {/* {currentPage[3] == "Mis" ? <th component="th" scope="row">Action(Retake)</th> : null} */}
            </tr>
          </thead>
          <tbody
          // onClick={(e) => {
          //   if (e.target.className == "0") {
          //     let d = "";
          //     rows.forEach(i => {
          //       if (i["Ref. No."] == e.target.textContent)
          //         d = i;
          //     })
          //     props.handleRef(d);

          //   }
          // }}
          // onMouseOver={(e) => {
          //   // Your onMouseOver logic here
          //   if (e.target.className == "0") {
          //     // e.target.style.backgroundColor = 'lightblue';
          //     e.target.style.cursor = 'pointer';
          //   }

          // }}
          >


            {/* {console.log(filteredRows.length)} */}

            {filteredRows.map((row, i) => (

              <tr
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                className={i}
              >
                <td component="th" scope="row">{i + 1}</td>
                {form_data[currentPage[2]][currentPage[3]].map((i, j) => {
                  if (i.type == "image") {
                    // console.log(j);
                    if (JSON.parse(row["data1"])[j] == undefined) {
                      return <td className={j} component="th" scope="row"> </td>;
                    }
                    // else if(typeof(JSON.parse(row["data1"])[j]) == "object" ){
                    //   return <td className={j} component="th" scope="row"><img height="50px" width={"40px"} src={JSON.parse(row["data1"])[j][i.title]} ></img></td>
                    // }
                    return <td className={j} component="th" scope="row" onClick={() => { handleClick(row["id"], i.title) }}><img height="50px" width={"40px"} src={JSON.parse(row["data1"])[j][i.title]}></img></td>
                  }
                  else if (row["Area of Construction"]) {

                    if (i.title == "Area of Construction.4F.numberofKitchen") {
                      return <td className={j} component="th" scope="row">{row["Area of Construction"]["4F"].numberofKitchen}</td>
                    } else if (i.title == "Area of Construction.4F.numberofRoom") {
                      return <td className={j} component="th" scope="row">{row["Area of Construction"]["4F"].numberofRoom}</td>
                    } else if (i.title == "Area of Construction.4F.numberofToilet") {
                      return <td className={j} component="th" scope="row">{row["Area of Construction"]["4F"].numberofToilet}</td>
                    } else if (i.title == "Area of Construction.4F.numberofhall") {
                      return <td className={j} component="th" scope="row">{row["Area of Construction"]["4F"].numberofhall}</td>
                    } else if (i.title == "Area of Construction.4F.totalArea") {
                      return <td className={j} component="th" scope="row">{row["Area of Construction"]["4F"].totalArea}</td>
                    }


                    else if (i.title == "Area of Construction.5F.numberofKitchen") {
                      return <td className={j} component="th" scope="row">{row["Area of Construction"]["5F"].numberofKitchen}</td>
                    } else if (i.title == "Area of Construction.5F.numberofRoom") {
                      return <td className={j} component="th" scope="row">{row["Area of Construction"]["5F"].numberofRoom}</td>
                    } else if (i.title == "Area of Construction.5F.numberofToilet") {
                      return <td className={j} component="th" scope="row">{row["Area of Construction"]["5F"].numberofToilet}</td>
                    } else if (i.title == "Area of Construction.5F.numberofhall") {
                      return <td className={j} component="th" scope="row">{row["Area of Construction"]["5F"].numberofhall}</td>
                    } else if (i.title == "Area of Construction.5F.totalArea") {
                      return <td className={j} component="th" scope="row">{row["Area of Construction"]["5F"].totalArea}</td>
                    }
                    else if (i.title == "Area of Construction.BF.numberofKitchen") {
                      return <td className={j} component="th" scope="row">{row["Area of Construction"]["BF"].numberofKitchen}</td>
                    } else if (i.title == "Area of Construction.BF.numberofRoom") {
                      return <td className={j} component="th" scope="row">{row["Area of Construction"]["BF"].numberofRoom}</td>
                    } else if (i.title == "Area of Construction.BF.numberofToilet") {
                      return <td className={j} component="th" scope="row">{row["Area of Construction"]["BF"].numberofToilet}</td>
                    } else if (i.title == "Area of Construction.BF.numberofhall") {
                      return <td className={j} component="th" scope="row">{row["Area of Construction"]["BF"].numberofhall}</td>
                    } else if (i.title == "Area of Construction.BF.totalArea") {
                      return <td className={j} component="th" scope="row">{row["Area of Construction"]["BF"].totalArea}</td>
                    } else if (i.title == "Area of Construction.FF.numberofKitchen") {
                      return <td className={j} component="th" scope="row">{row["Area of Construction"]["FF"].numberofKitchen}</td>
                    } else if (i.title == "Area of Construction.FF.numberofRoom") {
                      return <td className={j} component="th" scope="row">{row["Area of Construction"]["FF"].numberofRoom}</td>
                    } else if (i.title == "Area of Construction.FF.numberofToilet") {
                      return <td className={j} component="th" scope="row">{row["Area of Construction"]["FF"].numberofToilet}</td>
                    } else if (i.title == "Area of Construction.FF.numberofhall") {
                      return <td className={j} component="th" scope="row">{row["Area of Construction"]["FF"].numberofhall}</td>
                    } else if (i.title == "Area of Construction.FF.totalArea") {
                      return <td className={j} component="th" scope="row">{row["Area of Construction"]["FF"].totalArea}</td>
                    } else if (i.title == "Area of Construction.GF.numberofKitchen") {
                      return <td className={j} component="th" scope="row">{row["Area of Construction"]["GF"].numberofKitchen}</td>
                    } else if (i.title == "Area of Construction.GF.numberofRoom") {
                      return <td className={j} component="th" scope="row">{row["Area of Construction"]["GF"].numberofRoom}</td>
                    } else if (i.title == "Area of Construction.GF.numberofToilet") {
                      return <td className={j} component="th" scope="row">{row["Area of Construction"]["GF"].numberofToilet}</td>
                    } else if (i.title == "Area of Construction.GF.numberofhall") {
                      return <td className={j} component="th" scope="row">{row["Area of Construction"]["GF"].numberofhall}</td>
                    } else if (i.title == "Area of Construction.GF.totalArea") {
                      return <td className={j} component="th" scope="row">{row["Area of Construction"]["GF"].totalArea}</td>
                    } else if (i.title == "Area of Construction.MZ.numberofKitchen") {
                      return <td className={j} component="th" scope="row">{row["Area of Construction"]["MZ"].numberofKitchen}</td>
                    } else if (i.title == "Area of Construction.MZ.numberofRoom") {
                      return <td className={j} component="th" scope="row">{row["Area of Construction"]["MZ"].numberofRoom}</td>
                    } else if (i.title == "Area of Construction.MZ.numberofToilet") {
                      return <td className={j} component="th" scope="row">{row["Area of Construction"]["MZ"].numberofToilet}</td>
                    } else if (i.title == "Area of Construction.MZ.numberofhall") {
                      return <td className={j} component="th" scope="row">{row["Area of Construction"]["MZ"].numberofhall}</td>
                    } else if (i.title == "Area of Construction.MZ.totalArea") {
                      return <td className={j} component="th" scope="row">{row["Area of Construction"]["MZ"].totalArea}</td>
                    } else if (i.title == "Area of Construction.SF.numberofKitchen") {
                      return <td className={j} component="th" scope="row">{row["Area of Construction"]["SF"].numberofKitchen}</td>
                    } else if (i.title == "Area of Construction.SF.numberofRoom") {
                      return <td className={j} component="th" scope="row">{row["Area of Construction"]["SF"].numberofRoom}</td>
                    } else if (i.title == "Area of Construction.SF.numberofToilet") {
                      return <td className={j} component="th" scope="row">{row["Area of Construction"]["SF"].numberofToilet}</td>
                    } else if (i.title == "Area of Construction.SF.numberofhall") {
                      return <td className={j} component="th" scope="row">{row["Area of Construction"]["SF"].numberofhall}</td>
                    } else if (i.title == "Area of Construction.SF.totalArea") {
                      return <td className={j} component="th" scope="row">{row["Area of Construction"]["SF"].totalArea}</td>
                    } else if (i.title == "Area of Construction.TF.numberofKitchen") {
                      return <td className={j} component="th" scope="row">{row["Area of Construction"]["TF"].numberofKitchen}</td>
                    } else if (i.title == "Area of Construction.TF.numberofRoom") {
                      return <td className={j} component="th" scope="row">{row["Area of Construction"]["TF"].numberofRoom}</td>
                    } else if (i.title == "Area of Construction.TF.numberofToilet") {
                      return <td className={j} component="th" scope="row">{row["Area of Construction"]["TF"].numberofToilet}</td>
                    } else if (i.title == "Area of Construction.TF.numberofhall") {
                      return <td className={j} component="th" scope="row">{row["Area of Construction"]["TF"].numberofhall}</td>
                    } else if (i.title == "Area of Construction.TF.totalArea") {
                      return <td className={j} component="th" scope="row">{row["Area of Construction"]["TF"].totalArea}</td>
                    }
                  } else if (JSON.parse(row["data1"])[j] == undefined) {
                    return <td className={j} component="th" scope="row"></td>;
                  }
                  return <td className={j} component="th" scope="row">
                    {/* {console.log(JSON.parse(row["data1"])[j])} */}
                    {JSON.parse(row["data1"])[j][i.title]}</td>
                })}
                {currentPage[3] == "Mis" ? null :  <td component="th" scope="row" id='displayMiss'><Button id={i} onClick={handleEdit}>edit</Button></td>}
               
                <td component="th" scope="row"><Button id={i} onClick={handleDelete}>delete</Button></td>
                {/* {currentPage[3] == "Mis" ? <td component="th" scope="row"><Button id={i} onClick={retakeData}> Retake</Button></td> : null} */}

              </tr>
            ))}
          </tbody>

        </Table>

        {/* <Button onClick={() => {
                  let imgss = [];
                  for(let i = 0; i < imgss.length; i++){
                      imgss.pop();
                  }
              db.collection("images")
                      .get()
                      .then((snapshot) => {
                      if(snapshot.docs.length>0){
                          snapshot.docs.forEach((doc) => {
                          imgss.push(doc.data());
                      });
                      // console.log(imgss);
                      setImg(<img height="50px" width={"40px"} src={imgss[0].avatar}></img>)
                  }})
                }}>Image</Button> */}
        {/* {imgDemo} */}
      </div>
    </div>
  );
}