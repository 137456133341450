export const menu_data = {
    'Master': ["Bank"
        // ,"Bank Info"
        , "Employee"],
    'Resi Vist': ["Intimation", "Mis",
        //\\ "Schedule","Mis","Inspection Done","Create Report","Modify Report","Final Report",
        // "Modify After Final"
    ],
    'JLG Group': ["Intimation", "Mis",
        //  "Inspection Done", "Create Report", "Modify Report", "Final Report",
        // "Modify After Final"
    ],
    'Office Visit': ["Intimation", "Mis"],
    'Report': ['Create Report', 'Report Format', 'Generate Report']
}

export const form_data =
{
    "Master": {

        'Bank': [{ title: 'Ref. No.', type: 'text', required: true },
        { title: 'Bank Name', type: 'text', required: true },

            // {title: 'Personal Email', type: 'email',required : true}, 
            // {title: 'Phone Number', type: 'number',required : true},
            // {title :"Current Date", type: "date",required : true} 
        ],
        'Bank Info': [
            { title: 'Ref. No.', type: 'text', required: true },
            { title: 'Bank Name', type: ['datadropdown', ['Bank Name', 'Master/Bank']], required: true },
            { title: 'Bank Phon Number', type: "autofill", "parentField": ["Bank Name", "Master/Bank", "Phone Number", "Bank Name"], required: true },
            { title: 'Branch', type: 'text', required: true },
            { title: 'IFSC Code', type: 'text', required: true },
            { title: "Bank Address", type: "text", required: true },
            { title: "Bank Division", type: "text", required: true },
            { title: "Bank Contact Person Name", type: "text", required: true },
            { title: "Bank Contact Person Number", type: "text", required: true },
            { title: "Bank Contact Person Email Id", type: "text", required: true },
            { title: "Bank Manager Name", type: "text", required: true },
            { title: "Bank Manager Number", type: "number", required: true },
            { title: "Bank Manager Email Id", type: "email", required: true },
            { title: "Extra Remark", type: "text", required: true },
            { title: "Current Date", type: "date", required: true }
        ],
        'Employee': [
            { title: "Empolyee Name", type: "text", required: true },
            { title: "Mobile Number", type: "number", required: true },
            { title: 'Email Id', type: 'email', required: true },
            { title: "Address", type: "text", required: true },
            { title: "Aadhar Number", type: "number", required: true },
            { title: "Pan Number", type: "text", required: true },
            // {title : "Profile", type : "image",required : true},
            { title: "Password", type: "password", required: true },
            { title: "Current Date", type: "date", required: true }
        ]
    },
    'Resi Vist': {
        'Intimation': [
            { title: 'Ref. No.', type: 'number', required: true },
            { title: 'Name Of The Customer', type: 'text', required: true },
            { title: 'Case Allotment Date', type: 'date', required: true },
            { title: 'Address', type: 'text', required: true },
            { title: "Status", type: ["dropdown", ["Fresh", "Completed"]], required: true },
            { title: 'Empolyee Name', type: ["datadropdown", ["Empolyee Name", "Master/Employee"]], required: true },
            { title: 'Visit photo', type: 'image', required: true },

        ],
        // "Group ": [
        //     { title: 'Ref. No.', type: 'number', required: true },
        //     { title: 'Group Name', type: 'text', required: true },
        //     // {title: 'Name Of The Customer', type: 'text',required : true},
        //     { title: 'Case Allotment Date', type: 'date', required: true },
        //     { title: "Status", type: ["dropdown", ["Fresh", "Completed"]], required: true },

        //     { title: 'Empolyee Name', type: ["datadropdown", ["Empolyee Name", "Master/Employee"]], required: true },
        //     { title: 'Visit photo', type: 'image', required: true },
        // ],
        'Mis': [
            { title: 'Ref. No.', type: 'number', required: true },
            { title: 'Name Of The Customer', type: 'text', required: true },
            { title: 'Case Allotment Date', type: 'date', required: true },
            { title: 'Address', type: 'text', required: true },
            { title: "Status", type: ["dropdown", ["Fresh", "Completed"]], required: true },
            { title: 'Empolyee Name', type: ["datadropdown", ["Empolyee Name", "Master/Employee"]], required: true },
            { title: 'Visit photo', type: 'image', required: true },
            { title: 'Met Person Name', type: 'text', required: true },
            { title: 'Resi Staybility', type: 'text', required: true },
            { title: 'Family Members And Earning Person', type: 'text', required: true },
            { title: 'Neighbour Confirmation', type: 'text', required: true },
            { title: 'Photo', type: 'image' },

        ],
    }, "JLG Group": {
        'Intimation': [
            { title: 'Ref. No.', type: 'number', required: true },
            { title: 'Group Name', type: 'text', required: true },
            { title: 'Case Allotment Date', type: 'date', required: true },
            { title: 'Address', type: 'text', required: true },
            { title: "Status", type: ["dropdown", ["Fresh", "Completed"]], required: true },

            { title: 'Empolyee Name', type: ["datadropdown", ["Empolyee Name", "Master/Employee"]], required: true },
            { title: 'Visit photo', type: 'image', required: true },
        ],
        "Mis": [
            { title: 'Ref. No.', type: 'number', required: true },
            { title: 'Group Name', type: 'text', required: true },
            
            { title: 'Case Allotment Date', type: 'date', required: true },
            { title: 'Address', type: 'text', required: true },
            { title: "Status", type: ["dropdown", ["Fresh", "Completed"]], required: true },
            { title: 'Empolyee Name', type: ["datadropdown", ["Empolyee Name", "Master/Employee"]], required: true },
            { title: 'Visit photo', type: 'image', required: true },
            { title: 'Ref. No.', type: 'number', required: true },
            { title: 'Met Person Name', type: 'text', required: true },
            { title: 'Resi Staybility', type: 'text', required: true },
            { title: 'Family Members And Earning Person', type: 'text', required: true },
            { title: 'Neighbour Confirmation', type: 'text', required: true },
            { title: 'Photo1', type: 'image' },
            { title: 'Ref. No.', type: 'number', required: true },
            { title: 'Met Person Name', type: 'text', required: true },
            { title: 'Resi Staybility', type: 'text', required: true },
            { title: 'Family Members And Earning Person', type: 'text', required: true },
            { title: 'Neighbour Confirmation', type: 'text', required: true },
            { title: 'Photo2', type: 'image' },
            { title: 'Ref. No.', type: 'number', required: true },
            { title: 'Met Person Name', type: 'text', required: true },
            { title: 'Resi Staybility', type: 'text', required: true },
            { title: 'Family Members And Earning Person', type: 'text', required: true },
            { title: 'Neighbour Confirmation', type: 'text', required: true },
            { title: 'Photo3', type: 'image' },
            { title: 'Ref. No.', type: 'number', required: true },
            { title: 'Met Person Name', type: 'text', required: true },
            { title: 'Resi Staybility', type: 'text', required: true },
            { title: 'Family Members And Earning Person', type: 'text', required: true },
            { title: 'Neighbour Confirmation', type: 'text', required: true },
            { title: 'Photo4', type: 'image' },
            { title: 'Ref. No.', type: 'number', required: true },
            { title: 'Met Person Name', type: 'text', required: true },
            { title: 'Resi Staybility', type: 'text', required: true },
            { title: 'Family Members And Earning Person', type: 'text', required: true },
            { title: 'Neighbour Confirmation', type: 'text', required: true },
            { title: 'Photo5', type: 'image' },
               ],

    },
    'Office Visit': {
        'Intimation': [
            { title: 'Ref. No.', type: 'number', required: true },
            { title: 'Name Of The Customer', type: 'text', required: true },
            { title: 'Case Allotment Date', type: 'date', required: true },
            { title: 'Address', type: 'text', required: true },
            { title: "Status", type: ["dropdown", ["Fresh", "Completed"]], required: true },
            { title: 'Empolyee Name', type: ["datadropdown", ["Empolyee Name", "Master/Employee"]], required: true },
            { title: 'Visit photo', type: 'image', required: true },

        ],
        // "Group ": [
        //     { title: 'Ref. No.', type: 'number', required: true },
        //     { title: 'Group Name', type: 'text', required: true },
        //     // {title: 'Name Of The Customer', type: 'text',required : true},
        //     { title: 'Case Allotment Date', type: 'date', required: true },
        //     { title: "Status", type: ["dropdown", ["Fresh", "Completed"]], required: true },

        //     { title: 'Empolyee Name', type: ["datadropdown", ["Empolyee Name", "Master/Employee"]], required: true },
        //     { title: 'Visit photo', type: 'image', required: true },
        // ],
        'Mis': [
            { title: 'Ref. No.', type: 'number', required: true },
            { title: 'Name Of The Customer', type: 'text', required: true },
            { title: 'Case Allotment Date', type: 'date', required: true },
            { title: 'Address', type: 'text', required: true },
            { title: "Status", type: ["dropdown", ["Fresh", "Completed"]], required: true },
            { title: 'Empolyee Name', type: ["datadropdown", ["Empolyee Name", "Master/Employee"]], required: true },
            { title: 'Visit photo', type: 'image', required: true },
            { title: 'Met Person Name', type: 'text', required: true },
            { title: 'Resi Staybility', type: 'text', required: true },
            { title: 'Family Members And Earning Person', type: 'text', required: true },
            { title: 'Neighbour Confirmation', type: 'text', required: true },
            { title: 'Photo', type: 'image' },

        ],
    },
}
