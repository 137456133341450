import { Select } from '@mui/material';
import React from 'react'
import Editor from './Editor';
import Employee from './Employee';
import SelectMenu from './SelectMenu'
import Storagee from './Storagee';

export default function Home() {
  return (
    <div style={{margin : "10px"}}>
      <Employee/>
    </div>
  )
}
