
import React, {Component} from 'react'
import "./LoaderComp.css"


export default class Bill extends Component {

  constructor(props){
    super(props);

  }

  
  render(){

    return (
        <>
        <h1 className='billing'>
        This page development mode
        </h1>
        <div>
          {alert("This page development mode")}
          </div>
        </>
     
  )
}
}
