import React, { Component } from 'react'
import {db} from './firebase'
// import {collection, query, where, getDocs, updateDoc} from 'firebase'
import 'firebase/firestore';
import './firebase'
import LoaderComp from './LoaderComp'; // Import your Loader component

export default class TemplateFetch extends Component {
    constructor(props){
        super(props)
        this.state = {rows : [], email : '', address : '', name: '',isLoading : false}
        this.fetchAll = this.fetchAll.bind(this);
        // this.showData = this.showData.bind(this);
    }

    fetchAll(){
        let rows = [];
        for(let i = 0; i < rows.length; i++){
            rows.pop();
        }
        this.setState({ isLoading: true });
        db.collection("templates")
            .get()
            .then((snapshot) => {
            if(snapshot.docs.length>0){
                snapshot.docs.forEach((doc) => {
                rows.push(doc.data());
            });
            this.setState({rows : rows});
            this.props.getTemplateData(rows);
            this.setState({ isLoading: false });
        }
        })
    }

    componentDidMount(){
        this.fetchAll();
    }

    render(){
        return ;
    }

}
