import { MDBContainer } from 'mdb-react-ui-kit'
import React, { useState } from 'react'
import { Button, Table } from 'react-bootstrap'

export default function ReportTableComp(props) {


    let handleEdit = (e) => {
            // console.log(e.target.classList);
        props.handleEdit(e.target.classList[0]-1);
        // window.location.reload(false);
    }

    let handleDelete = (e) => {
        // console.log(e.target.classList);
        props.handleDelete(e.target.classList[0]-1);
        // window.location.reload(false);
    }

  return (
    <div>
        <MDBContainer  className="p-3 my-5 d-flex flex-column">
        <h1>Report Account</h1>
        <Table striped bordered hover responsive border={"1px solid black"}>
        <thead>
        <tr>
            <th>Head One</th>
            <th>Action(Edit)</th>
            <th>Action(Delete)</th>
        </tr>
        </thead>
        <tbody>
            {/* {console.log(props.data)} */}
            {props.data.map((i,j) =>
                 <tr>
                    <td>{i}</td>
                    <td><Button onClick={handleEdit} className={j+1}>edit</Button></td>
                    <td><Button onClick={handleDelete} className={j+1}>delete</Button></td>
                </tr>
            )}
        </tbody>
    </Table>
    </MDBContainer>
    </div>
  )
}
