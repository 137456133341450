// Loader.js
import React from 'react';
// import { css } from '@emotion/react';
// import { PulseLoader } from 'react-spinners';
import "./LoaderComp.css"



const LoaderComp = () => {
  return (

    
    <div className="full-page-loader">
      <div className="loader">
        <div className="spinner"></div>
        {/* <p>{message}</p> */}
      </div>
    </div>
  );
};

export default LoaderComp;