import { MDBBtn, MDBContainer, MDBInput } from 'mdb-react-ui-kit'
import React, { useState , useEffect } from 'react'
import { Button, Table } from 'react-bootstrap'
import { form_data } from './Menu-data'
import {db} from './firebase'
import LoaderComp from './LoaderComp'; // Import your Loader component


export default function ScheduleComp(props) {
    const [idx, setidx] = useState();
    const [isLoading, setIsLoading] = useState(false);
   
    const rows = [];
    let data = props.data
    data.map((i)=>{
        rows.push(i.data);
    })
   

    const currentDate = new Date().toISOString().slice(0, 10);
    const [startDate, setStartDate] = useState(currentDate);
    const [endDate, setEndDate] = useState(currentDate);
    const [filteredRows, setFilteredRows] = useState(rows);
    const handleEdit = (e) => {
        document.querySelector(".form").style.display = ""
        setidx(e.target.id);
        document.querySelectorAll("input")[0].value = data[e.target.id].data["Intimation Contact Date"];
        document.querySelectorAll("input")[1].value = data[e.target.id].data["Intimation Time"];
    }

    const handleUpdate = () => {
        let updated =  data[idx];
        updated.data["Intimation Contact Date"] = document.querySelectorAll("input")[0].value;
        updated.data["Intimation Time"] = document.querySelectorAll("input")[1].value;
        setIsLoading(true);
        db.collection("mess").doc(updated.id).update({data: updated.data});
        setIsLoading(false);
        document.querySelector(".form").style.display = "none"
        // let dataa = db.collection("mess").doc(updated.id)
    }

    const handleTime = (e) =>{
        let idd = e.target.id;

        props.data.map((i,k)=>{
            if(k == idd){
                db.collection("mess").doc(i.id).update({TimeOut: "No"});

            }
        })
        
    }

    const handleFilter = () => {
        if (startDate && endDate) {
    
          let filteredData = rows.filter((row) => {
            const rowDate = new Date(row["Current Date"]); // Ensure 'date' field matches the date format
          const filterStartDate = new Date(startDate);
          const filterEndDate = new Date(endDate);
  
          return rowDate >= filterStartDate && rowDate <= filterEndDate;
          });
          // console.log(filteredData);
          setFilteredRows(filteredData);
        }
      };


      // useEffect(() => {
      //   let ar = [];
      //   let filteredData = rows.filter((row) => {
      //     if(row["Inspection Date"] == currentDate){
      //       ar.push(row)
      //     }
      //   });
      //   // console.log(ar);
      //   // setFilteredRows(ar);
        
      // }, [rows]);


  return (
    <div>
      <div>
        <div  className='text-end'>   
        Start Date: 
        <input className='me-3' type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
        End Date: 
        <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
      </div>
      <div className='text-end mt-3 mb-3'>
      <button className='filterbtn' onClick={handleFilter}>Apply Filter</button>
      </div>
      </div>
    <div class="form" style={{display : 'none'}}>

    <MDBContainer  className="p-3 my-5 d-flex flex-column w-50">
        <MDBInput wrapperClass='mb-4' min="0" label="Intimation Date" className='form1' type="date" autoFill={false}/>
        <MDBInput wrapperClass='mb-4' min="0" label="Intimation Time" className='form1' type="time" autoFill={false}/>
        <MDBBtn key="mb-4 w-100" className="mb-4 w-100" onClick={handleUpdate}>Update</MDBBtn> :
    </MDBContainer>
    </div>

    <Table striped bordered hover responsive border={"1px solid black"}>
        <thead>
        <tr>
            {form_data['Vehicle Valuation']['New Intimation'].map(i => {
                return <th>{i.title}</th>
            })}
            
            <th component="th" scope="row">Action(Edit)</th>
            <th component="th" scope="row">Action(TimeOut)</th>
            </tr>
        </thead>
        <tbody>
            {filteredRows.map((i,k) => {
                return <tr>
                {form_data['Vehicle Valuation']['New Intimation'].map(j => {
                  return <td>{i[j.title]}</td>
                })}
                <td component="th" scope="row"><Button id={k}onClick={handleEdit}>edit</Button></td>
                <td component="th" scope="row"><Button id={k} onClick={handleTime }>TimeOut</Button></td>
                </tr>
                {isLoading && <LoaderComp />}
            })}
        </tbody>
    </Table>
    </div>
    
  )
}
