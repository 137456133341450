import React, { useEffect, useState } from 'react'
import './homepage.css'
import CheckIcon from '@mui/icons-material/Check';
import PersonIcon from '@mui/icons-material/Person';
import PollIcon from '@mui/icons-material/Poll';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import HomeIcon from '@mui/icons-material/Home';
import RuleIcon from '@mui/icons-material/Rule';
import DataFetech from './dataFetech';
import { logDOM } from '@testing-library/react';
import { components } from 'react-select';
import { Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
// import fs from "fs";

export default function HomePage() {
    const [dataValues, setDataValues] = useState([0, 4, 7, 12, 23, 12])

    const [charts, setCharts] = useState()

    const [tab, setTable] = useState();
    // const currentDate = new Date().toISOString().slice(0, 10);
    // console.log(currentDate);
    const currentDate = new Date().toLocaleDateString('en-GB');
    let currentDates = new Date(); // Get the current date
    let oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
  
    // let oneWeek = oneWeekAgo.toLocaleDateString('en-GB');


    let oneMonthsAgos = new Date();
    oneMonthsAgos.setDate(oneWeekAgo.getDate() - 30);
    let oneMonth = oneMonthsAgos.toLocaleDateString('en-GB');


    // console.log(oneWeek);
    // console.log(oneWeekAgo);
    //   oneWeekAgo.setDate(currentDates.getDate() - 7); // Calculate one week ago
    //   console.log(oneWeekAgo);
    // const handleClick = (e) => {
    //     let element;
    //     // console.log(element);
    //     switch (e.target.nodeName) {
    //         case "H6":
    //             element = e.target.nextElementSibling.textContent
    //             break;

    //         case "SPAN":
    //             element = e.target.textContent;
    //             break;

    //         case "svg":
    //             element = e.target.parentElement.nextElementSibling.children[1].textContent;
    //             break;

    //         case "DIV":
    //             element = e.target.classList.contains("one") ? e.target.nextElementSibling.children[1].textContent : e.target.children[1].textContent
    //             break;
    //     }
    //     switch (element) {
    //         case "Employees":
    //             window.location.pathname = "/Employee"
    //             break;

    //         case "Total Surveys":
    //         case "Completed Surveys":
    //         case "Incomplete Surveys":
    //         case "Vehicle Surveys":
    //             window.location.pathname = "/Table/Vehicle Valuation/New Intimation"
    //             break;

    //         case "Property Surveys":
    //             window.location.pathname = "/Table/Property Valuation/New Intimation"
    //             break;
    //     }
    // }

    const getData = (data) => {
        // console.log(data);
        let emp = 0;
        let total = 0;
        let incomp = 0;
        let vehicle = 0;
        let property = 0;
        let todaydone = [];
        let weakdone = [];
        let monthdone = [];
        let nameEmp = [];
        let matchingInspections = [];
        let itemsByName = [];
        data.forEach(i => {
            if (i.field == "Master/Employee")
                emp++;
            if (i.field == "Vehicle Valuation/New Intimation" || i.field == "Property Valuation/New Intimation") {
                if (i.field == "Vehicle Valuation/New Intimation") {
                    vehicle++;
                    if (Object.keys(i.data).indexOf("Report Data") == -1)
                        // console.log(Object.keys(i.data).indexOf("Report Data"));
                        incomp++;
                }
                if (i.field == "Property Valuation/New Intimation") {
                    property++
                    // console.log(i.data);
                    if (Object.keys(i.data).indexOf("Inspection Time") != -1)
                        incomp++;
                }
                total++;
            }
        })
        dataValues[0] = emp;
        dataValues[1] = total;
        dataValues[2] = total;
        dataValues[3] = incomp;
        dataValues[4] = vehicle;
        dataValues[5] = property;

        data.map((i) => {
            if (i.field == 'Master/Employee') {
                nameEmp.push(i.data["Empolyee Name"])
            }
        })
     

        nameEmp.forEach(name => {
            itemsByName[name] = [];
        });

        data.forEach((item) => {
            if(item.data == undefined){
                return null
            }else{
             if (item.data["Inspected By"]) {
                const name = item.data["Inspected By"].toLowerCase();
                if (nameEmp.includes(name)) {
                    // Add the item to the corresponding array
                    itemsByName[name].push(item);
                }
            }
            }
           
        })
        // console.log(itemsByName);

        // data.map((i) => {
        //     if (
        //         ["Vehicle Valuation/New Intimation", "Property Valuation/New Intimation", "Property Valuation/Inspection Done", "Vehicle Valuation/Inspection Done"].includes(i.field)
        //     ) {
        //         if (i.data["Inspected By"]) {
        //             // console.log(i, i.data["Inspected By"]);
        //         }
        //     }
        // })

        // data.forEach((item) => {
        //     if (
        //         ["Vehicle Valuation/New Intimation", "Property Valuation/New Intimation", "Property Valuation/Inspection Done", "Vehicle Valuation/Inspection Done"].includes(item.field)
        //     ) {
        //         // Check if the Inspection Date matches the current date
        //         if (item.data["Status"] == "Inspection Done") {
        //             if (item.data["Inspection Date"] === currentDate) {
        //                 todaydone.push(item);
        //             }
        //             // Check if the Inspection Date falls within the last week
                  
        //             // Check if the Inspection Date falls within the last month
        //             if (item.data["Inspection Date"] >= oneMonth && item.data["Inspection Date"] <= currentDate) {
        //                 monthdone.push(item);
        //             }
        //         }
        //     }
        // });





       
//     console.log(data);
//     const now = new Date();
//     const formattedDate = now.toISOString().replace(/:/g, '-').split('.')[0]; // Format: "YYYY-MM-DDTHH-MM-SS"
//     // Create a JSON filename with the current date and time
//     // const filename = `${formattedDate}.json`;
//     const folderName = 'backup';
// // Create a JSON filename with the current date and time inside the "backup" folder
// // const filename = `${folderName}/${formattedDate}.json`;
//     // Create a JSON string from the Firestore data
//     const jsonData = JSON.stringify(data, null, 2);
//     // Write the JSON string to a file
//     // fs.writeFileSync(filename, jsonData);
//     console.log(`Data saved to ${filename}`);

const downloadJSON = (data, filename) => {
    const jsonStr = JSON.stringify(data);
    const blob = new Blob([jsonStr], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    
    URL.revokeObjectURL(url); // Clean up
  };




        data.forEach((item) => {
            if (
                ["Vehicle Valuation/New Intimation", "Property Valuation/New Intimation", "Property Valuation/Inspection Done", "Vehicle Valuation/Inspection Done"].includes(item.field)
            ) {
                // Check if the Inspection Date matches the current date
                if (item.data["Status"] == "Completed") {
                    if(item.data["date"] == undefined ){
                        return null;    
                }
                    else
                    {
                        const itemDateParts = item.data["date"].split('/');
                        const itemDate = new Date(`${itemDateParts[2]}-${itemDateParts[1]}-${itemDateParts[0]}`);
                        
                    if (itemDate.toDateString() === currentDates.toDateString()) {
                        todaydone.push(item.data["Inspected By"]);
                    }
                }
                }
            }
        });

      

        data.forEach((item) => {
            if (
                ["Vehicle Valuation/New Intimation", "Property Valuation/New Intimation", "Property Valuation/Inspection Done", "Vehicle Valuation/Inspection Done"].includes(item.field)
            ) {
                // Check if the Inspection Date matches the current date
                if (item.data["Status"] == "Completed") {
            
                    if(item.data["date"] == undefined ){
                            return null;    
                    }
                        else
                        {
                            const itemDateParts = item.data["date"].split('/');
                            const itemDate = new Date(`${itemDateParts[2]}-${itemDateParts[1]}-${itemDateParts[0]}`);
                        
                            if (itemDate >= oneWeekAgo && itemDate <= currentDates) {
                              weakdone.push(item.data["Inspected By"]);
                            }
                        }
                   
                
                    
                }
            }
        });

        data.forEach((item) => {
            if (
                ["Vehicle Valuation/New Intimation", "Property Valuation/New Intimation", "Property Valuation/Inspection Done", "Vehicle Valuation/Inspection Done"].includes(item.field)
            ) {
                // Check if the Inspection Date matches the current date
                if(item.data["date"] == undefined ){
                    return null;    
            }
                else
                {
                if (item.data["Status"] == "Completed") {
                    const itemDateParts = item.data["date"].split('/');
                    const itemDate = new Date(`${itemDateParts[2]}-${itemDateParts[1]}-${itemDateParts[0]}`);
                    if (itemDate >= oneMonthsAgos && itemDate <= currentDates) {
                        monthdone.push(item.data["Inspected By"]);
                    }
                }
                }
            }
        });
           
      

            const uniqueStrings = [];
            const uniqueStringss = [];
            const uniqueStringsss = [];
            const todayCounts = [];
            const weakCounts = [];
            const MonthCounts = [];
            // Array ko traverse karte hue, har string ka count dictionary mein update karenge.
            todaydone.forEach(word => {
                const index = uniqueStrings.indexOf(word);
                if (index !== -1) {
                    todayCounts[index]++;
                } else {
                    uniqueStrings.push(word);
                    todayCounts.push(1);
                }
            });
            weakdone.forEach(word => {
                const index = uniqueStringss.indexOf(word);
                if (index !== -1) {
                    weakCounts[index]++;
                } else {
                    uniqueStringss.push(word);
                    weakCounts.push(1);
                }
            });
            monthdone.forEach(word => {
                const index = uniqueStringsss.indexOf(word);
                if (index !== -1) {
                    MonthCounts[index]++;
                } else {
                    uniqueStringsss.push(word);
                    MonthCounts.push(1);
                }
            });






            const countDictToday = {};
            todaydone.forEach(word => {
                if (countDictToday[word]) {
                    countDictToday[word] += 1;
                } else {
                    countDictToday[word] = 1;
                }
            });
            

            const countDictWeak = {};
            weakdone.forEach(word => {
                if (countDictWeak[word]) {
                    countDictWeak[word] += 1;
                } else {
                    countDictWeak[word] = 1;
                }
            });
            

            const countDictMonth = {};
            monthdone.forEach(word => {
                if (countDictMonth[word]) {
                    countDictMonth[word] += 1;
                } else {
                    countDictMonth[word] = 1;
                }
            });
            
            let a = [];
            let b =[];
            let c = [];
            for (const word in countDictToday) {
                a.push(`${word} Done By ${countDictToday[word]}`);
                // console.log(`The Employees Nanme '${word}' Total Survey Done By Today ${countDictToday[word]}`);
            }
            
            for (const word in countDictWeak) {
                // console.log(`the Employees nanme '${word}' Total Survey Done By Week ${countDictWeak[word]}`);
                b.push(`${word} Done By ${countDictWeak[word]}`)
            }
            for (const word in countDictMonth) {
                // console.log(`the Employees nanme '${word}' Total Survey Done By Month ${countDictMonth[word]}`);
                c.push(`${word} Done By ${countDictMonth[word]}`)
            }

     

        // })
        //  console.log(monthdone);
        // myNames.forEach(name => {
        //     console.log(`Items Inspected By ${name}:`);
        //     const itemsForName = matchingInspections.filter(item => item.data["Inspected By"].toLowerCase() === name);
        //     itemsForName.forEach(item => {
        //         // Print or process each item for the current name
        //         console.log(item);
        //     });
        // });

        // Print or further process todaydone, weakdone, and monthdone arrays
        // console.log(matchingInspections)            
        // console.log(todaydone.length);

        setCharts(<div>
        <div className="text-end">
        {/* <button >Download JSON</button> */}
        {/* <Button onClick={() => downloadJSON(data, 'backup.json')} style={{marginLeft: "10px", marginBottom: "0.4%",background: "#1976d2",fontSize: "15px", marginRight:"220px"}}>Download Backup</Button> */}
        </div>
            <div className='main mt-2'>
                <div className='rect'>
                    <div className='one' style={{ background: "#665ca7" }}><PersonIcon style={{ height: "80px", width: "80px", margin: "30px", marginBottom: "0px", marginRight:"215px"}} /></div>
                    <div className='two' style={{ background: "#7266ba" }}>
                        <h6 style={{ margin: "20px", fontSize: "30px", marginBottom: "0px" }}>{dataValues[0]}</h6>
                        <span style={{ marginLeft: "15px", fontSize: "20px" }}>Employees</span>
                    </div>
                </div>
                {/* <div className='rect'>
                    <div className='one' style={{ background: "#3b94dd" }}><PollIcon style={{ height: "80px", width: "80px", margin: "30px", marginBottom: "0px" }} /></div>
                    <div className='two' style={{ background: "#42a5f6" }}>
                        <h6 style={{ margin: "20px", fontSize: "30px", marginBottom: "0px" }}>{dataValues[1]}</h6>
                        <span style={{ marginLeft: "15px", fontSize: "20px" }}>Total Surveys</span>
                    </div>
                </div>
                <div className='rect'>
                    <div className='one' style={{ background: "rgb(242 182 48)" }}><RuleIcon style={{ height: "80px", width: "80px", margin: "30px", marginBottom: "0px" }} /></div>
                    <div className='two' style={{ background: "rgb(242 221 0)" }}>
                        <h6 style={{ margin: "20px", fontSize: "30px", marginBottom: "0px" }}>{dataValues[2]}</h6>
                        <span style={{ marginLeft: "15px", fontSize: "20px" }}>Completed Surveys</span>
                    </div>
                </div> */}
            </div>
            {/* <div className='main mt-5'>
                <div className='rect'>
                    <div className='one' style={{ background: "#de5474" }}><RuleIcon style={{ height: "80px", width: "80px", margin: "30px", marginBottom: "0px" }} /></div>
                    <div className='two' style={{ background: "#f75d81" }}>
                        <h6 style={{ margin: "20px", fontSize: "30px", marginBottom: "0px" }}>{dataValues[3]}</h6>
                        <span style={{ marginLeft: "15px", fontSize: "20px" }}>Incomplete Surveys</span>
                    </div>
                </div>
                <div className='rect'>
                    <div className='one' style={{ background: "#71bd1d" }}><DirectionsCarIcon style={{ height: "80px", width: "80px", margin: "30px", marginBottom: "0px" }} /></div>
                    <div className='two' style={{ background: "#7ed320" }}>
                        <h6 style={{ margin: "20px", fontSize: "30px", marginBottom: "0px" }}>{dataValues[4]}</h6>
                        <span style={{ marginLeft: "15px", fontSize: "20px" }}>Vehicle Surveys</span>
                    </div>
                </div>
                <div className='rect'>
                    <div className='one' style={{ background: "#de5474" }}><HomeIcon style={{ height: "80px", width: "80px", margin: "30px", marginBottom: "0px" }} /></div>
                    <div className='two' style={{ background: "#f75d81" }}>
                        <h6 style={{ margin: "20px", fontSize: "30px", marginBottom: "0px" }}>{dataValues[5]}</h6>
                        <span style={{ marginLeft: "15px", fontSize: "20px" }}>Property Surveys</span>
                    </div>
                </div>
            </div> */}
            {/* <div className='container'>
            
                <div className='row mt-5 text-center'>
                <div className='col-md-4 col-lg-4'>
                <h3>Survey Done By Today</h3>
                <span className='"text-center"'>{a.map((i)=> <tr>{i}</tr>)}</span>
                </div>
                <div className='col-md-4 col-lg-4' style={{borderRight:"2px solid" , borderLeft:"2px solid"}}>
                <h3>Survey Done By Last 7 days</h3>
                <span>{b.map((i)=> <tr>{i}</tr>)}</span>
                </div>
                <div className='col-md-4 col-lg-4'>
                <h3>Survey Done By Last 30 days</h3>
                <span>{c.map((i)=> <tr>{i}</tr>)}</span>
                </div>
                </div>
                
                <p></p>
                <p></p>
            </div> */}
        </div>)
    }

    return (
        <div>
            {/* <img style={{margin: "5px 40%" }} src={'https://firebasestorage.googleapis.com/v0/b/survey-app-3db7b.appspot.com/o/3808dc7e-7c97-da28-4b4d-b130089aab89?alt=media&token=96ee42a9-ca6d-4f6e-a9f8-b18b8ef8ad6e'}></img> */}
            <h1 style={{textAlign:"center",marginTop:"40px"}}>GD Associates</h1>
            <DataFetech isTemplate={false} getData={getData} />
            {charts}
        </div>
    )
}
