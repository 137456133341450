import React, { Component, useState } from 'react'
import { Table } from 'react-bootstrap';
import DataFetech from './dataFetech';
import { form_data } from './Menu-data';
import PropSchedule from './PropSchedule';
import ScheduleComp from './ScheduleComp';
import TableComp from './TableComp';
import LoaderComp from './LoaderComp'; // Import your Loader component


export default class VehicleSchedule extends Component{
  constructor(props){
    super(props);
    this.state = {fbData : [], data : [], table : [],isLoading : false ,startDate: null,rows: [], // Assuming you have a state for rows
    filteredRows: [],
      endDate: null,};
    this.child = React.createRef();
  }

  updateTable = () => {
   
    setTimeout(() => {
      this.child.current.fetchAll();
    }, 500)

  }

  getData = (data) => {
    let {fbData} = this.state;
    this.setState({ isLoading: true });
    fbData = [];
    data.forEach(i => {
      if(i.field == "Vehicle Valuation/New Intimation")
        fbData.push(i)
    })
    console.log(fbData);
    this.setState({data : fbData, table : <ScheduleComp data={fbData} updateTable={this.updateTable}/>});
    this.setState({ isLoading: false });
  }

  handleFilter = () => {
    console.log(this.state.tab);
    const { startDate, endDate, fbData } = this.state;
    
    if (startDate && endDate) {
      const filterStartDate = new Date(startDate);
      const filterEndDate = new Date(endDate);

      let filteredData = fbData.filter((row) => {
        const rowDate = new Date(row["Inspection Date"]); // Ensure 'Inspection Date' matches the date field name
        return rowDate >= filterStartDate && rowDate <= filterEndDate;
      });
        console.log({ filteredRows: filteredData });
      this.setState({ filteredRows: filteredData });
    }
  };

  handleStartDateChange = (e) => {
    this.setState({ startDate: e.target.value });
  };

  handleEndDateChange = (e) => {
    this.setState({ endDate: e.target.value });
  };



  render(){
    const { startDate, endDate } = this.state;
    return (
      <div style={{width : "94%", marginTop: "15px", marginLeft : "1%", marginBottom : "5%",height: "74vh",overflow: "auto"}}>
        <DataFetech isTemplate={false} getData={this.getData} ref={this.child}/>
      <div>
     
        <h1>Vehicle Schedule</h1>  
        {console.log(this.state.table)} 
        {this.state.table}
      </div>
      {this.state.isLoading && <LoaderComp />}
      </div>
    )
  }
}
