import * as React from 'react';
import { Component } from 'react';
import { db } from './firebase';
import {storage} from "./firebase"
import './firebase'
import TableComp from './TableComp';
import Form from "./form"
import DataFetech from './dataFetech';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { MDBBtn, MDBContainer, MDBInput } from 'mdb-react-ui-kit';
import exportFunc from './Excelexport';
import Table from 'react-bootstrap/Table';
import { form_data } from './Menu-data';
import AlertBox from './AlertBox';
import { Key } from '@mui/icons-material';
import "./DataTable.css"
import LoaderComp from './LoaderComp'; // Import your Loader component


export default class DataTable extends Component {
  constructor(props) {
    super(props)
    this.state = { rows: [], editData: {}, userData: [], refState: false, refData: [], validated: false, open: false, isLoading: false, delData: {} }
    this.updateTable = this.updateTable.bind(this);
    this.updateData = this.updateData.bind(this);
    this.child = React.createRef();
    this.formRef = React.createRef();
    let currentPage = window.location.pathname.split("/");
    const currentURL = window.location.href;
    this.isPropertyPage = currentURL.includes('Property');
    this.isVehiclePage = currentURL.includes('Vehicle');
    this.isMasterpage = currentURL.includes("/Table/Master/Bank%20Info");
    this.isMasterpages = currentURL.endsWith("/Table/Master/Bank");
    this.isVehicle = currentURL.endsWith("/Table/Vehicle%20Valuation/Mis");
  }

  getData = (userData) => {
    this.setState({ userData });
    let currentPage = window.location.pathname.split("/");
    currentPage.forEach((i, j) => {
      if (i.indexOf("%20") > -1)
        currentPage[j] = i.split("%20").join(" ");
    });
    let rows = [];
    console.log(currentPage);
    setTimeout(() => {
      userData.forEach(i => {
        let f = currentPage[3] == "Mis" ? "Intimation" : currentPage[3];
        if (i.field == `${currentPage[2]}/${f}`) {
          // console.log(`${currentPage[2]}/${f}`);
          // console.log(i);
          // i.data["id"] = i.id;
          rows.push(i);
          // console.log(rows);
        }
      })
      // console.log(rows);
      this.setState({ rows });
    }, 500)
  }

  updateTable = () => {
    setTimeout(() => {
      this.child.current.fetchAll();
    }, 500)
  }

  updateData = (dataField) => {
    // console.log(dataField);
    this.setState({ editData: dataField });
    // window.location.reload(false);
  }

  deleteData = (data) => {
    // console.log(data["id"]);
    // // db.collection("mess").doc(data.id).delete().then(() => {
    // this.setState({ open: true, delData: data });
    // // this.updateTable();
    // // });
    // const docId = data.key; // Assuming data["key"] contains the key of the node you want to delete
    // console.log(docId);
    
    // // Get a reference to the node you want to delete
    // const nodeRef = db.ref("User/" + docId);
    
    // // Remove the node
    // nodeRef.remove()
    //   .then(() => {
        this.setState({ open: true, delData: data });
    //     this.updateTable();
    //     console.log("Node successfully deleted!");
    //   })
    //   .catch((error) => {
    //     console.error("Error deleting node: ", error);
    //   });
    


  }

  
  deleteDataa = () => {
    let data = this.state.delData
    // console.log(data);
    
// const folderPath = `images/null/${data.id}`;
// const folderRef = storage.ref().child(folderPath);
// folderRef
//   .listAll()
//   .then((result) => {
//     result.items.forEach((itemRef) => {
//       console.log('Item in folder:', itemRef.fullPath);
//         itemRef
//           .delete()
//           .then(() => {
//             console.log('Image deleted successfully.');
//           })
//           .catch((error) => {
//             console.error('Error deleting image:', error);
//           });
      
//     });
//   })
//   .catch((error) => {
//     console.error('Error listing folder contents:', error);
//   });

//     this.setState({ open: false });
//     db.collection("mess").doc(data.id).delete().then(() => {
//       this.setState({ opend: true })
//       this.updateTable();
//     });
const docId = data.key; // Assuming data["key"] contains the key of the node you want to delete
// console.log(docId);

// Get a reference to the node you want to delete
const nodeRef = db.ref("User/" + docId);

// Remove the node
nodeRef.remove()
  .then(() => {
    this.setState({ open: false, delData: data });
    this.updateTable();
    // console.log("Node successfully deleted!");
  })
  .catch((error) => {
    // console.error("Error deleting node: ", error);
  });


  }

  handleUpdate = () => {
    let { rows } = this.state;
    console.log(this.state.userData);
    let a = [];
    let im = [];
    this.state.userData.map((i) => {
      // console.log(i.id);
      a.push(i.id);
    })
    // console.log(a);
    rows.forEach(i =>

      a.map((k) => {
        if (k == i.id) {
          // console.log(this.state.userData)
          this.state.userData.map((i) => {
            // console.log(i);
            if (i.id == k) {
              if (i.images) {
                i.images.map((l) => {
                  // console.log(l.imageUrl);
                  im.push(l);
                })
              }
            }
            // a.push(i.id);
          })
        }
      })
      // 
      // delete i.id
    )
    // console.log(im);
    exportFunc(rows);
    // exportFunc(im);
  }

  handleRef = (d) => {
    this.setState({ refState: true, refData: d });
  }

  validateInput = (field, value) => {
    console.log(window.location.pathname.split("/")[2], "Mis")
    if (window.location.pathname.split("/")[3] == "Mis") {

      return;
    }

    let ipfield;
    document.querySelectorAll('label').forEach(i => {
      if (i.textContent == field) {
        ipfield = i.previousElementSibling;
      }
    })
    let emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let tenDs = ["Mobile Number", "Phone Number", "Pan Number", "Bank Phon Number", "Bank Contact Person Number", "Bank Manager Number", "Customer Number"]
    let tweDs = ["Aadhar Number"]
    let mails = ["Personal Email", "Bank Contact Person Email Id", "Bank Manager Email Id"]
    let elevn = ["IFSC Code"]

    if (tweDs.indexOf(field) != -1) {
      if (value.length != 12) {
        if (!ipfield.classList.contains('is-invalid'))
          ipfield.classList.add('is-invalid')
      } else {
        if (ipfield.classList.contains('is-invalid'))
          ipfield.classList.remove('is-invalid')
      }
    }
    if (tenDs.indexOf(field) != -1) {
      if (value.length != 10) {
        if (!ipfield.classList.contains('is-invalid'))
          ipfield.classList.add('is-invalid')
      } else {
        if (ipfield.classList.contains('is-invalid')) {
          ipfield.classList.remove('is-invalid')
        }
      }
    }

    if (mails.indexOf(field) != -1) {
      let emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        if (!ipfield.classList.contains('is-invalid')) {
          ipfield.classList.add('is-invalid')
        }
      } else {
        if (ipfield.classList.contains('is-invalid')) {
          ipfield.classList.remove('is-invalid')
        }
      }
    }

    if (elevn.indexOf(field) != -1) {
      if (value.length != 11) {
        if (!ipfield.classList.contains('is-invalid'))
          ipfield.classList.add('is-invalid')
      } else {
        if (ipfield.classList.contains('is-invalid')) {
          ipfield.classList.remove('is-invalid')
        }
      }

    }
    let flag = 1;
    document.querySelectorAll('.required').forEach(i => {
      if (i.value == "") {
        flag = 0
      }
    })
    document.querySelectorAll('.form1').forEach(i => {
      if (i.classList.contains('is-invalid')) {
        flag = 0
      }
    })


    console.log(!!flag);
    this.setState({ validated: !!flag })
    return;

  }

  handleClose = () => {
    this.setState({ open: false, opend: false });
  }

  render() {
    if (this.props.userID == "true") {
      if (!this.state.refState)
        return (
      <div className='container-fluid'>
          <div className='row'>
            <Dialog
              open={this.state.open}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Record Added Successfully"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-description">
                  Are you sure you want to delete this record ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.deleteDataa} autoFocus>
                  Yes
                </Button>
                <Button onClick={this.handleClose} autoFocus>
                  No
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={this.state.opend}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Record Added Successfully"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-description">
                  Record Deleted Successfully
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose} autoFocus>
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
            <DataFetech isTemplate={false} getData={this.getData} ref={this.child} />
            <Form validated={this.state.validated} validateInput={this.validateInput} updateTable={this.updateTable} editData={this.state.editData} updateData={this.updateData} userData={this.state.userData} />
            <TableComp rows={this.state.rows} dataa ={this.state.userData} updateData={this.updateData} removeData={this.deleteData} handleRef={this.handleRef} />
            {/* <Button style={{
              marginLeft: "50%",
              marginTop: "-5%",
              background: "#005cb8",
              color: "white"
            }}
              onClick={this.handleUpdate}
            >export</Button> */}
          </div>
          </div>
        )
      else
        return (
          <div>
            <div style={{ display: "flex", margin: "5px", gap: "5px" }}>
              <MDBBtn onClick={() => this.setState({ refState: false })}>Back</MDBBtn>
              <MDBBtn onClick={(e) => {
                document.querySelectorAll('.form-notch').forEach(i => i.style.display = '')
                document.querySelector('.save-btn').style.display = '';
              }} className='edit-btn'>
                Edit</MDBBtn>
              <MDBBtn onClick={(e) => {
                document.querySelectorAll('.form-notch').forEach(i => i.style.display = 'none')
                document.querySelector('.save-btn').style.display = 'none';
                let data = {};
                document.querySelectorAll('td').forEach((i, j) => {
                  if (j % 2 != 0) {
                    data[i.previousElementSibling.textContent] = i.childNodes[0].children[0].value;
                  }
                })
                console.log(this.state.refData);
                db.collection("mess").doc(this.state.refData.id).update({ data: data });
              }} className='save-btn' style={{ display: 'none' }}>Save</MDBBtn>
            </div>
            <MDBContainer className="p-1 my-1 d-flex flex-column w-50">
              <Table striped bordered hover responsive border={"1px solid black"}>
                <thead>
                  <tr>
                    <th>Field</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>

                  {this.isPropertyPage && (form_data["Property Valuation"]["Mis"].map(i => {
                    {/* { console.log(form_data["Vehicle Valuation"]["New Intimation"]) } */ }
                    return <tr>
                      <td>{i.title}</td>
                      <td className='inp-td' id={i.title}><MDBInput></MDBInput></td>

                    </tr>
                  }))}
                  {this.isVehiclePage && (form_data["Vehicle Valuation"]["Mis"].map(i => {
                    return <tr>
                      <td>{i.title}</td>
                      <td className='inp-td' id={i.title}><MDBInput></MDBInput></td>
                    </tr>
                  }))}
                  {this.isMasterpage && (form_data["Master"]["Bank Info"].map(i => {
                    {/* { console.log(form_data["Master"]["Bank Info"]) } */ }
                    return <tr>
                      <td>{i.title}</td>
                      <td className='inp-td' id={i.title}><MDBInput></MDBInput></td>
                    </tr>
                  }))}
                  {this.isMasterpages && (form_data["Master"]["Bank"].map(i => {
                    {/* { console.log(form_data["Master"]["Bank Info"]) } */ }
                    return <tr>
                      <td>{i.title}</td>
                      <td className='inp-td' id={i.title}><MDBInput></MDBInput></td>
                    </tr>
                  }))}
                  {/* {Object.keys(this.state.refData).map(i => {
              {console.log(form_data["Vehicle Valuation"]["New Intimation"])}
              return <tr>
              <td>{i}</td>
              <td className='inp-td' id={i}><MDBInput></MDBInput></td>
              </tr>
            })} */}
                </tbody>
                <span style={{ display: "none" }}>
                  {setTimeout(() => {
                    document.querySelectorAll('.form-notch').forEach(i => {
                      i.style.display = 'none'
                    })
                    function flattenObject(obj, parentKey = '') {
                      let result = {};

                      for (const key in obj) {
                        const newKey = parentKey ? `${parentKey}.${key}` : key;

                        if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
                          const flattened = flattenObject(obj[key], newKey);
                          result = { ...result, ...flattened };
                        } else {
                          result[newKey] = obj[key];
                        }
                      }

                      return result;
                    }
                    const flattenedObject = flattenObject(this.state.refData);
                    console.log(flattenedObject);
                    document.querySelectorAll('td').forEach((i, j) => {
                      if (j % 2 !== 0 && j != 0) {
                        i.childNodes[0].children[0].value = flattenedObject[i.previousElementSibling.textContent];
                      }
                    })

                  }, 0)
                  }
                </span>
              </Table>
              <div id='imgVecal'>
                {this.state.refData.id && (
                  <span style={{ display: "none" }}><div id='imgVecal'>
                    {
                      setTimeout(() => {
                        let a = [];
                        if (this.state.refData.id) {
                          this.state.userData.map((p) => {
                            if (this.state.refData.id === p.id) {

                              if (p.images || p.data) {
                                a.push(p.images);
                              }
                            }
                          });
                        }
                        const imgVecal = document.getElementById("imgVecal");
                        a.map((l) => {
                          if (l === undefined || l == "") {
                            return null;
                          } else {

                            l.map((k) => {
                              const img = document.createElement("img");
                              img.src = Object.values(k);
                              imgVecal.appendChild(img);
                            });
                          }
                        });
                      }, 0)
                    }
                  </div>
                  </span>
                )}
              </div>
            </MDBContainer>
            <div style={{ display: "flex", margin: "5px", gap: "5px" }}>
              <div id="imgVecal"></div>
              {/* <div id="imgVecal123"></div> */}
              <MDBBtn onClick={() => this.setState({ refState: false })}>Back</MDBBtn>
              <MDBBtn onClick={(e) => {
                document.querySelectorAll('.form-notch').forEach(i => i.style.display = '')
                document.querySelector('.save-btn').style.display = '';
              }} className='edit-btn'>
                Edit</MDBBtn>
            </div>
            <div>{this.state.isLoading && <LoaderComp />}</div>
          </div>
        )
    } else {
      window.location.pathname = "/"
    }
  }
}