import { MDBContainer, MDBInput, MDBValidation, MDBValidationItem } from 'mdb-react-ui-kit'
import React, { Component, useState } from 'react'
import TableComp from './TableComp';
import { app } from "./firebase";
import './firebase'
import { db } from './firebase'
import { Button } from 'react-bootstrap';
import uuid from 'react-uuid';
import { components } from 'react-select';
import Table from 'react-bootstrap/Table';
import DataFetech from './dataFetech';
import Validation from './Validation';
import LoaderComp from './LoaderComp'; // Import your Loader component
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

export default class Employee extends Component {
  constructor(props) {
    super(props)
    this.state = { record: {}, fileUrl: null, emps: [], users: [], open: false, passwordon: false, rows: [], data: [], vals: { "Vehicle Type": "",}, validatedIn: false, isLoading: false };
    this.child = React.createRef();
    this.formRef = React.createRef();
  }
  onSubmit = async () => {
    // db.collection("emp_images").doc("username" + uuid()).set({
    //   name: "username",
    //   avatar: this.state.fileUrl,
    // });
  };

  handleSubmit = () => {
  // console.log(this.state.vals);
  let data = {}
  let { rows } = this.state;
  
  // let empObj = { data: data, field: "Master/Employee", id: "emp" + uuid() };


  this.onSubmit().then(i => {
    document.querySelectorAll('.form1').forEach(i => {
      if (i.type == "file") {
        data["Profile"] = this.state.fileUrl;
      } else {
        data[i.nextElementSibling.textContent] = i.value;
      }
    });
    let empObj = { data: data, field: "Master/Employee/vType", id: "emp" + uuid() };
  
    console.log(empObj);
  
      // // this.setState({ isLoading: true });
      db.collection("mess").doc(empObj.id).set(empObj).then(i => {
        window.location.reload(false);
        // this.setState({ isLoading: false });
      });
  

  });



  }

  



 

  getData = (data) => {
    let { rows } = this.state;
    data.forEach(i => {
      if (i.field == "Master/Employee/vType")
        rows.push(i)
    })
    data = rows;
    // console.log(data,rows);
    let a = rows.map((i, k) => {
      return <tr>
        {["Sr", "Vehicle Type"].map((j) => {
          if (j == "Sr")
            return <td>{k + 1}</td>
          if (j == "Profile")
            // return <td><img style={{ height: "50px", width: "50px" }} src={i.data[j]}></img></td>
          return <td>{i.data[j]}</td>
        })}
      </tr>
    })
    // console.log(rows);
    this.setState({ rows: a, data })
   
  }

  updateData = () => { }

  removeData = () => { }

  handleInput = (e) => {
    let { vals } = this.state;
    let field = e.target.nextElementSibling.textContent
    vals[field] = e.target.value;
    // this.validateInput(e)
    this.setState({ vals })
  }


  render() {

    return (
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-lg-4 col-mg-4' style={{borderTop: "2px solid" ,borderLeft: "2px solid", borderRight: "2px solid", height: "80vh" ,overflow: "auto"}}>
            <DataFetech isTemplate={false} getData={this.getData} ref={this.child} />
            <div className="d-flex flex-column container-fluid" style={{width: "350px"}}>
              <div>
                <MDBValidation>
                <span className='text-center'>
                  <h1 className='mt-3'>Vehicle Type</h1>
                  </span>
                  <p></p>
                  {/* {this.state.inputs} */}
      
                  <div>

                    <MDBValidationItem style={{ width: "100%" }} className='col-md-6' feedback='Please provide a valid Empolyee Name.' invalid>
                    <MDBInput
  className="form1"
  wrapperClass='mb-4'
  min="0"
  label='Vehicle Type'
  type="text"
  onChange={this.handleInput}
  value={this.state.vals['Vehicle Type']}
  readOnly={false}  // Make sure this is not set to true
  disabled={false}  // Make sure this is not set to true
></MDBInput>
                    </MDBValidationItem>
                    <p></p>
                    <p></p></div>

                  <Button className='submit-btn' onClick={this.handleSubmit}>Submit</Button>
                </MDBValidation>
              </div>
              <p></p>
            </div>
          </div>
          <div className='col-lg-8 col-mg-8' style={{borderTop: "2px solid" ,borderLeft: "2px solid", borderRight: "2px solid"}}>
          <h1 className='text-center mt-3'>Data Of Vechile Type</h1>
            <Table striped bordered hover responsive border={"1px solid black"}  className='mt-5' style={{width:"200px",textAlign:"center"}}>
              <thead>
                <tr>
                  <th>Vehicle Type</th>
                </tr>
              </thead>
              <tbody>
              {/* {this.state.rows)} */}
{/* { console.log(this.state.rows)} */}
                {this.state.data.map(i => <tr><td>{i["data"]["Vehicle Type"]}</td></tr>)}
                {/* {this.state.rows} */}
                {/* {this.state.rows.map(i => i)} */}
                {/* {this.state.vals} */}
                {/* {console.log(this.state.vals["Empolyee Name"])} */}
                {/* {this.state.vals["Empolyee Name"]} */}
              </tbody>
            </Table>

          </div></div>
      </div>
    )
  }

}
