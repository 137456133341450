// import {initializeApp} from 'firebase';
// import { getFirestore } from '@firebase/firestore';
import firebase from 'firebase';
import "firebase/storage";


const firebaseConfig = {
    apiKey: "AIzaSyBKLZ1JWkbZkrrYd24CzmrLQFNHIvjzZVM",
    authDomain: "surveryv2.firebaseapp.com",
    databaseURL: "https://surveryv2-default-rtdb.firebaseio.com",
    projectId: "surveryv2",
    storageBucket: "surveryv2.appspot.com",
    messagingSenderId: "110007115006",
    appId: "1:110007115006:web:fbc850bbf440692d53d9af",
    measurementId: "G-JDSJWD67DC"
  };

// Initialize Firebase
// firebase.initializeApp(firebaseConfig);

// export const firestore = firebase.firestore();


export const app = firebase.initializeApp(firebaseConfig)
export const db = firebase.database();
export const storage = firebase.storage()