import { app } from "./firebase";
import React, { useState, useRef, useEffect } from 'react';
import {
  MDBContainer,
  MDBBtn,
  MDBInput,
  MDBValidationItem,
}
  from 'mdb-react-ui-kit';
import { useNavigate, useParams } from 'react-router';
import { form_data } from './Menu-data';
import './firebase'
import { db } from './firebase'
import { App } from "./firebase"
import uuid from 'react-uuid';
import "./form.css"
import "./forms/app.css"
import DropDown from './forms/DropDown';
import DataFetech from './dataFetech';
import { storage } from './firebase';
import { ref } from 'firebase/storage'
import { Button } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoaderComp from './LoaderComp'; // Import your Loader component
import jsonData from './backup.json'; // Import JSON data


function Form(props) {
  let { formName } = useParams();
  let formData;

  let currentPage = window.location.pathname.split("/");
  currentPage.forEach((i, j) => {
    if (i.indexOf("%20") > -1)
      currentPage[j] = i.split("%20").join(" ");
  });
  const [formVals, setFormVals] = useState({ "Company Name": "TVS" })
  const [editState, setEditState] = useState(!!Object.keys(props.editData).length)
  const [optValue, setOptValue] = useState({});
  const [fbData, setFbData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openon, setOpenon] = useState(false)
  const [alertMsg, setAlertMsg] = useState("")
  const [fileUrl, setFileUrl] = useState(null);
  const [post, setPost] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const currentDate = new Date().toISOString().slice(0, 10);
  const [startDate, setStartDate] = useState(currentDate);
  const [data, setData] = useState([]);
  const [jsonData, setJsonData] = useState(null);
  const [userImg, setUserImg] = useState()
  const handleChange = (e) => {
    formVals[e.target.id] = e.target.value;
    setFormVals(formVals);
  }

  const handleOption = (data) => {
    form_data[currentPage[2]][currentPage[3]].map(i => {
      let labels = document.querySelectorAll('.drop')
      if (i.type == "autofill") {
        if (i.parentField[0] == data.target.previousElementSibling.previousElementSibling.textContent.slice(0, -3)) {
          document.querySelectorAll('input').forEach(k => {
            if (k.id == i.title) {
              fbData.forEach(o => {
                if (o.field == i.parentField[1] && o.data[i.parentField[3]] == data.target.value) {
                  k.value = o.data[i.parentField[2]];
                }
              })
            }
          })
        }
      }
    });
  }

  const [validatedIn, setValidatedIn] = useState(false);

  const validateInput = (e) => {
    let field = e.target.nextElementSibling.textContent
    // props.validateInput(field,e.target.value)
    let value = e.target.value
    let ipfield;
    document.querySelectorAll('label').forEach(i => {
      if (i.textContent == field) {
        ipfield = i.previousElementSibling;
      }
    })

    let emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let tenDs = ["Mobile Number", "Phone1","Mobile","Contact No of Agent / SM", "Phone Number", "Pan Number", "Bank Phon Number", "Bank Contact Person Number", "Bank Manager Number", "Customer Number", "Contact No.", "Intimation Contact Number", "Inspector Contact No", "Contact No of Agent / SM"]
    let tweDs = ["Aadhar Number"]
    let mails = ["Personal Email", "Bank Contact Person Email Id", "Bank Manager Email Id"]
    let elevn = ["IFSC Code",""]
    let alphabetic = ["Customer Name",  "State","Region","Customer Name","LOAN STATUS","Group Name","Met Person Name","Bank Name", "Branch", "Bank Contact Person Name", "Bank Manager Name", "Extra Remark", "Intimated By", "Remarks", "Bill Status", "Name of SM/Agent"]
    let fiveDS = ["Zipcode"];

    if (tweDs.indexOf(field) != -1) {
      if (value.length != 12) {
        if (!ipfield.classList.contains('is-invalid'))
          ipfield.classList.add('is-invalid')
      } else {
        if (ipfield.classList.contains('is-invalid'))
          ipfield.classList.remove('is-invalid')
      }
    }
    if (tenDs.indexOf(field) != -1) {
      if (value.length != 10) {
        if (!ipfield.classList.contains('is-invalid'))
          ipfield.classList.add('is-invalid')
      } else {
        if (ipfield.classList.contains('is-invalid')) {
          ipfield.classList.remove('is-invalid')
        }
      }
    }
if (fiveDS.indexOf(field) != -1) {
      if (value.length != 5) {
        if (!ipfield.classList.contains('is-invalid'))
          ipfield.classList.add('is-invalid')
      } else {
        if (ipfield.classList.contains('is-invalid')) {
          ipfield.classList.remove('is-invalid')
        }
      }
    }
    if (mails.indexOf(field) != -1) {
      let emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        if (!ipfield.classList.contains('is-invalid')) {
          ipfield.classList.add('is-invalid')
        }
      } else {
        if (ipfield.classList.contains('is-invalid')) {
          ipfield.classList.remove('is-invalid')
        }
      }
    }

    if (alphabetic.indexOf(field) != -1) {
      let emailFormat = /^[a-zA-Z]/
      if (!/^[a-zA-Z\s]+$/.test(value)) {
        if (!ipfield.classList.contains('is-invalid')) {
          ipfield.classList.add('is-invalid')
        }
      } else {
        if (ipfield.classList.contains('is-invalid')) {
          ipfield.classList.remove('is-invalid')
        }
      }
    }
    if (elevn.indexOf(field) != -1) {
      if (value.length != 11) {
        if (!ipfield.classList.contains('is-invalid'))
          ipfield.classList.add('is-invalid')
      } else {
        if (ipfield.classList.contains('is-invalid')) {
          ipfield.classList.remove('is-invalid')
        }
      }

    }
    let flag = 1;
    document.querySelectorAll('.required').forEach(i => {
      if (i.value == "") {
        flag = 0
      }
    })
    document.querySelectorAll('.form1').forEach(i => {
      if (i.classList.contains('is-invalid')) {
        flag = 0
      }
    })


    // setValidatedIn(!!flag)
    // return;
  }



  if (!!Object.keys(props.editData).length) {
    document.querySelectorAll(".form1").forEach((i, j) => {
      console.log(JSON.parse(props.editData.data1)[j]["Visit photo"]);
      if (i.type === "select-one") {
        i.value = JSON.parse(props.editData.data1)[j][i.id];
      } else if (i.type === "file") {
        // For file inputs, you cannot programmatically set their value
        // Clear the file input instead
        // console.log(JSON.parse(props.editData.data1)[j][i.id]);

        // setFileUrl(JSON.parse(props.editData.data1)[9][i.id]);
        // fileUrl = JSON.parse(props.editData.data1)[j][i.id]
        // i.value = JSON.parse(props.editData.data1)[j][i.id];

        var userImg = document.getElementById("userImg");

        // Parse the JSON string to a JavaScript object
        // var parsedData = JSON.parse(props.editData.data1);

        // Access the value associated with the key i.id in the 9th element of the array
        // console.log(JSON.parse(props.editData.data1)[j][i.id]);
        var srcValue = JSON.parse(props.editData.data1)[j][i.id];

        // Change the src attribute to the new image path
        userImg.setAttribute("src", srcValue);
      } else {

        i.value = JSON.parse(props.editData.data1)[j][i.id] == undefined ? "" : JSON.parse(props.editData.data1)[j][i.id];
      }

    })
  }
  formData = form_data[currentPage[2]][currentPage[3]].map((i, j) => {
    if (currentPage[3] == "Mis") {
      let a = form_data["Resi Vist"]["Intimation"].map(i => i.title)
      if (a.indexOf(i.title) != -1 && i.title != "Ref. No.") {
        return
      }
    }
    if (Array.isArray(i.type) && i.type[0] == "dropdown") {
      return <div>
        <label className='drop'>{i.title} : </label><br></br>
        <select className='form1 dropdown' style={{ width: "100%", height: "48%" }}
            id={i.title}>
          {i.type[1].map(i => <option>{i}</option>)}
        </select>
      </div>
    }
    if (Array.isArray(i.type) && i.type[0] == "datadropdown") {
      let title = i.type[1][0];
      let field = i.type[1][1];
      let newArr = [];
      props.userData.forEach(j => {
        if (j.field == field) {
          newArr.push(j.data[title]);
        }
      })
      // return <DropDown label={i.title} options={newArr} handleOption={handleOption}/>
      return <div>
        <label>{i.title} : </label><br></br>
        <select onChange={handleOption} className='form1 dropdown' style={{ width: "100%", height: "48%" }} id={i.title}>
          {newArr.map(i => <option >{i}</option>)}
        </select>
      </div>
    }
    if (i.type == "image") {
      return <div style={{ width: "100%" }} className='col-md-6'> <label>Visit photo : </label>
        <input label="Profile" className='form1' type="file"
          onChange={(e) => onFileChange(e.target.files[0])}
          id={i.title} style={{ width: "100%" }} />
        <div className='text-end'>
          <img src={fileUrl} style={{ height: "50px", width: "50px" }} id="userImg"></img>
          {isLoading && <LoaderComp />}
        </div></div>
      // return <MDBInput onChange={validateInput} wrapperClass='' min="0" label={i.title} className={`form1 ${i.required ? 'required' : ''}`} id={i.title} key={i.title} type={'file'} autoFill={false} />;
      //   return <MDBValidationItem style={{ width: "100%" }} className='col-md-6' feedback={`Please provide a valid ${i.title.toLowerCase()}.`} invalid>
      //   <MDBInput onChange={validateInput} wrapperClass='' min="0" label={i.title} className={`form1 ${i.required ? 'required' : ''}`} id={i.title} key={i.title} type={'file'} autoFill={false} />
      // </MDBValidationItem>
    }
    if (i.type == "date") {
      return <MDBInput  wrapperClass='' min="0" label={i.title} className={`form1 ${i.required ? 'required' : ''}`} id={i.title} key={i.title} type={i.type} autoFill={false} />
    }
    if (i.type == "autofill") {
      return <MDBInput wrapperClass='' min="0" label={i.title} className={`form1 ${i.title.split(" ").join("-").toLowerCase()}`} id={i.title} key={i.title} type={i.type} autoFill={true} />
    } else {
      return <MDBValidationItem style={{ width: "100%" }} className='col-md-6' feedback={`Please provide a valid ${i.title.toLowerCase()}.`} invalid>
        <MDBInput onChange={validateInput} wrapperClass='' min="0" label={i.title} className={`form1 ${i.required ? 'required' : ''}`} id={i.title} key={i.title} type={i.type} autoFill={false} />
      </MDBValidationItem>
    }

  })

  // const handleSave = async (e) => {
  //   e.preventDefault();
  //   let flag = 1;
  //   document.querySelectorAll('.required').forEach(i => {
  //     i.classList.contains('is-invalid')
  //     if (i.value == "") {
  //       setOpenon(true);
  //       i.classList.add('is-invalid')
  //       // i.classList.remove('is-invalid')
  //       flag = 0;
  //     }
  //   })
  //   document.querySelectorAll('.form1').forEach(i => {
  //     if (i.classList.contains('is-invalid')) {
  //       flag = 0;
  //     }
  //   })

  //   if (!flag)
  //     return;

  //   let data = {}
  //   document.querySelectorAll('.form1').forEach(i => {
  //     if (i.classList.contains('dropdown') || i.classList.contains('datadropdown')) {
  //       data[i.previousElementSibling.previousElementSibling.textContent.slice(0, -3)] = i.value;

  //     }
  //     else
  //       data[i.id] = i.value;
  //   })
  //   let dataa = { field: `${currentPage[2]}/${currentPage[3]}`, data, id: uuid() };
  //   setIsLoading(true);
  //   console.log(data);
  //   // db.collection("mess").doc(dataa.id).set(dataa).then(i => {
  //   //   setAlertMsg("Record Saved Successfully");
  //   //   setOpen(true)
  //   //   document.querySelectorAll("input").forEach(i => {
  //   //     i.value = "";
  //   //   })
  //   //   props.updateTable();
  //   //   setIsLoading(false);
  //   // })

  // }

  //   const handleSave = (e) => {
  //     e.preventDefault();
  //     let flag = 1;
  //     document.querySelectorAll('.required').forEach(i => {
  //         i.classList.contains('is-invalid')
  //         if (i.value == "") {
  //             setOpenon(true);
  //             i.classList.add('is-invalid')
  //             // i.classList.remove('is-invalid')
  //             flag = 0;
  //         }
  //     })
  //     document.querySelectorAll('.form1').forEach(i => {
  //         if (i.classList.contains('is-invalid')) {
  //             flag = 0;
  //         }
  //     })

  //     if (!flag)
  //         return;

  //     let data = [];
  //     document.querySelectorAll('.form1').forEach(i => {
  //         let item = {};
  //         if (i.classList.contains('dropdown') || i.classList.contains('datadropdown')) {
  //             item[i.previousElementSibling.previousElementSibling.textContent.slice(0, -3)] = i.value;
  //         }
  //         else {
  //             item[i.id] = i.value;
  //         }
  //         data.push(item);
  //     });

  //     let newData = { field: `${currentPage[2]}/${currentPage[3]}`, data, id: uuid() };
  //     setIsLoading(true);
  //     console.log(newData);

  //     // Retrieve existing data from localStorage if available
  //     let existingData = localStorage.getItem('myData');
  //     if (existingData) {
  //         existingData = JSON.parse(existingData);
  //     } else {
  //         existingData = [];
  //     }


  // existingData.push(newData);

  // // Save combined data back to localStorage
  // localStorage.setItem('myData', JSON.stringify(existingData));
  // // Trigger the download
  // downloadJsonFile(existingData, 'myData.json');

  //   }
  //   const downloadJsonFile = (data, filename) => {
  //     try {
  //         // Convert data to JSON string
  //         console.log("h1");
  //         const jsonData = JSON.stringify(data);

  //         // Create a Blob from the JSON data
  //         const blob = new Blob([jsonData], { type: 'application/json' });

  //         // Create a URL for the Blob
  //         const url = URL.createObjectURL(blob);

  //         // Create a temporary anchor element
  //         const a = document.createElement('a');
  //         a.href = url;
  //         a.download = filename || 'data.json';

  //         // Programmatically trigger a click on the anchor element
  //         document.body.appendChild(a);
  //         a.click();

  //         // Clean up by removing the anchor element and revoking the object URL
  //         document.body.removeChild(a);
  //         URL.revokeObjectURL(url);
  //     } catch (error) {
  //         console.error('Error creating or triggering download:', error);
  //     }
  // };

  const handleSave = async (e) => {
    e.preventDefault();
    let flag = 1;
    document.querySelectorAll('.required').forEach(i => {


      i.classList.contains('is-invalid')
      if (i.value == "") {
        setOpenon(true);
        i.classList.add('is-invalid')
        // i.classList.remove('is-invalid')
        flag = 0;
      }
    })
    document.querySelectorAll('.form1').forEach(i => {
      if (i.classList.contains('is-invalid')) {
        flag = 0;
      }
    })

    if (!flag)
      return;

    let data = [];
    document.querySelectorAll('.form1').forEach(i => {
      let item = {};
     
      if (i.type == "text" || i.type == "date" || i.type == "number"  ) {
        item[i.id] = i.value;
      } else if (i.type == "file") {
        // onChangeImg(i.files[0]);
        // return item[i.id] = downloadURL
        // setIsLoading(true);
        // onFileChange(i.files[0]);
        // console.log(fileUrl);
        item[i.id] = fileUrl;
        // setIsLoading(false);
      } else {
        item[i.id] = i.value;
      }
      data.push(item);
    });
    console.log(data);
    
    let data1 = JSON.stringify(data)
    console.log(data1);
    let newData = { field: `${currentPage[2]}/${currentPage[3]}`, data1, id: uuid() };
    setIsLoading(true);
    
    console.log(newData);
    const ref = db.ref('User');

    // Example: Add data to the 'User' node
    ref.push(newData)
      .then(() => {
        console.log("Data saved successfully!");
        document.querySelectorAll("input").forEach(i => {
          i.value = "";

        })
        props.updateTable();
        
        setIsLoading(false);
        setFileUrl(null);
        window.location.reload(false);
        // console.log(fileUrl);
      })
      .catch((error) => {
        console.error("Error adding data: ", error);
      });
  };


  const onChangeImg = async (e) => {
    const file = e; // Get the file object
    const storageRef = storage.ref(); // Reference to the root of your storage bucket

    // Create a reference to the location where you want to store the file
    const fileRef = storageRef.child(file.name);

    try {
      // Upload the file to Firebase Storage
      const snapshot = await fileRef.put(file);
      console.log("File uploaded successfully");


      // Get the download URL for the uploaded file
      const downloadURL = await snapshot.ref.getDownloadURL();
      setUserImg(downloadURL); // Assuming setUserImg is a state update function

      // Use downloadURL here or pass it to another function
      // doSomethingWithDownloadURL(downloadURL);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  }

  const downloadJsonFile = async (data, filename) => {
    try {
      // Convert data to JSON string
      const jsonString = JSON.stringify(data, null, 2);

      // Create a new Blob
      const blob = new Blob([jsonString], { type: 'application/json' });

      // Check if the browser supports the File System API
      if (window.showSaveFilePicker) {
        // Use the File System API to create the file
        const options = {
          types: [
            {
              description: 'JSON files',
              accept: {
                'application/json': ['.json'],
              },
            },
          ],
        };
        const fileHandle = await window.showSaveFilePicker(options);
        const writable = await fileHandle.createWritable();
        await writable.write(blob);
        await writable.close();
        console.log('File successfully created.');
      } else if (navigator.msSaveBlob) {
        // For Internet Explorer and Edge browsers
        navigator.msSaveBlob(blob, filename || 'myData.json');
        console.log('Download initiated successfully.');
      } else {
        // If the File System API is not supported, fallback to anchor element download
        console.warn('File System API not supported in this browser. Falling back to anchor element download.');
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename || 'myData.json';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        console.log('Download initiated successfully.');
      }
    } catch (error) {
      console.error('Error creating or triggering download:', error);
    }
  };



  // // Example usage:
  // const jsonData = { key: 'value' };
  // createAndSaveJsonFile(jsonData, 'example.json');


  const handleUpdate = (e) => {
    // console.log(e);
    e.preventDefault();
    let flag = 1;
    document.querySelectorAll('.required').forEach(i => {
      i.classList.contains('is-invalid');
      if (i.value == "") {
        i.classList.add('is-invalid');
        // setOpenon(true);
        flag = 0
      }
    })
    document.querySelectorAll('.form1').forEach(i => {
      if (i.classList.contains('is-invalid')) {
        flag = 0
      }
    })

    if (!flag)
      return;

    let data = currentPage[3] == "Mis" ? props.editData : {};
    let d = []
    // console.log(data);
    document.querySelectorAll('.form1').forEach(i => {
      let data2 = {};
      if (i.type == "select-one") {
        data2[i.previousElementSibling.previousElementSibling.textContent.slice(0, -3)] = i.value;
      }
      else if (i.type == "file") {
        // onChangeImg(i.files[0]);
        // return item[i.id] = downloadURL
        // setIsLoading(true);
        // onFileChange(i.files[0]);
        var userImg = document.getElementById("userImg");
        console.log(userImg.src);
        console.log(i.value);
        data2[i.id] = userImg.src;
        // setIsLoading(false);
      }
      else {

        data2[i.id] = i.value;
      }
      console.log(data2);
     d.push(data2)
    })
    // console.log(data2);
    // setIsLoading(true);
    let data1 = JSON.stringify(d)
    // console.log(data1);
    let newData = { field: `${currentPage[2]}/${currentPage[3]}`, data1, id: props.editData.id };
    console.log(newData);

    // db.collection("mess").doc(props.editData.id).update({ data: data }).then(i => {
    //   setAlertMsg("Record Updated Successfully")
    //   setOpen(true)
    //   setIsLoading(false);
    // });

    const docId = props.editData.key; // Assuming data["key"] contains the key of the node you want to update

    // Get a reference to the node you want to update
    const nodeRef = db.ref("User/" + docId);

    // Define the updates you want to apply

    // Update the node with the defined updates
    nodeRef.update(newData)
      .then(() => {
        // console.log("Node successfully updated!");
        window.location.reload(false); // Reload the page or update UI as needed
      })
      .catch((error) => {
        // console.error("Error updating node: ", error);
      });



    props.updateTable();
    props.updateData({});
    document.querySelectorAll("input").forEach(i => {
      i.value = "";
    })
  }
  const getData = (data) => {
    setFbData(data);
  }
  const onSubmit = async () => {
    db.collection("images").doc("username").set({
      name: "username",
      avatar: fileUrl,
    });
  };

  const onFileChange = async (e) => {
    try {
      console.log(e);
      setIsLoading(true);
      const file = e; // Get the file object
      const storageRef = app.storage().ref(); // Reference to the root of your storage bucket
      const fileRef = storageRef.child(file.name); // Create a reference to the location where you want to store the file

      // Upload the file to Firebase Storage
      await fileRef.put(file);

      // Get the download URL for the uploaded file
      const downloadURL = await fileRef.getDownloadURL();
      setFileUrl(downloadURL); // Update the state with the download URL

      console.log("File uploaded successfully:", downloadURL);
      setIsLoading(false);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };


  const handleClose = () => {
    setOpen(false);

  }
  const handleCloses = () => {
    setOpenon(false);
  }
  {
    if (!!Object.keys(props.editData).length || currentPage[3] != "Mis")
      return (
        <div className="col-lg-4 col-mg-4 " id="phoneView" style={{ borderTop: "2px solid", borderLeft: "2px solid", borderRight: "2px solid", height: "80vh", overflow: "auto" }} 
        // style={(form_data[currentPage[2]][currentPage[3]].length > 5) ? { display:"none" } : {  borderTop: "2px solid", borderLeft: "2px solid", borderRight: "2px solid", height: "80vh", overflow: "auto"  }}
        >
          <div className="d-flex flex-column container-fluid" style={{ width: "350px" }}>
            { }
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Record Saved Successfully"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} autoFocus>
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={openon}
              onClose={handleCloses}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title" style={{ color: "red" }}>
                {"* All Mandatory Fields Are Required"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloses} autoFocus >
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
            <DataFetech isTemplate={false} getData={getData} />
            <h1 className="mt-3">{currentPage[2] + ' ' + formName}</h1>
            <p></p>
            <div className='form-container' style={(form_data[currentPage[2]][currentPage[3]].length > 10) ? { gridTemplateColumns: "1fr 1fr", width: "150%", marginLeft: "-25%" } : { gridTemplateColumns: "" }}>

              {formData}
            </div>
            {!!Object.keys(props.editData).length ?
              <MDBBtn key={"mb-4 w-100"} className="mb-4 w-100" onClick={handleUpdate}>Update</MDBBtn> :
              <MDBBtn key={"mb-4 w-101"} className="mb-4 w-100" onClick={handleSave} style={{ backgroundColor: "#35424e" }}>Submit</MDBBtn>
            }{isLoading && <LoaderComp />}
            {/* <input type="file" onChange={onFileChange} />
          <Button onClick={onSubmit}>Press</Button> */}
            {/* Show loader when isLoading is true */}
          </div>
        </div>
      );
  }

}

export default Form;