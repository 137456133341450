import { MDBContainer, MDBInput, MDBValidation, MDBValidationItem } from 'mdb-react-ui-kit'
import React, { Component, useState } from 'react'
import TableComp from './TableComp';
import { app } from "./firebase";
import './firebase'
import { db } from './firebase'
import { Button } from 'react-bootstrap';
import uuid from 'react-uuid';
import { components } from 'react-select';
import Table from 'react-bootstrap/Table';
import DataFetech from './dataFetech';
import Validation from './Validation';
import LoaderComp from './LoaderComp'; // Import your Loader component
// import Dialog from '@mui/material/Dialog';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

export default class Employee extends Component {
  constructor(props) {
    super(props)
    this.state = { record: {}, fileUrl: null, emps: [], users: [], open: false, passwordon: false, rows: [], data: [], vals: { "Empolyee Name": "", "Mobile Number": "", "Email Id": "", "Address": "", "Aadhar Number": "", "Pan Number": "", "Profile": "", "Password": "" }, validatedIn: false, isLoading: false };
    this.child = React.createRef();
    this.formRef = React.createRef();
  }

  onSubmit = async () => {
    // db.collection("emp_images").doc("username" + uuid()).set({
    //   name: "username",
    //   avatar: this.state.fileUrl,
    // });
  };

  onFileChange = async (e) => {
    this.setState({ isLoading: true });
    const file = e.target.files[0];
    const storageRef = app.storage().ref();
    const fileRef = storageRef.child(uuid());
    await fileRef.put(file);
    this.setState({ fileUrl: await fileRef.getDownloadURL() });
    const dp = await fileRef.getDownloadURL();
    console.log(this.state.fileUrl);
    this.setState({ isLoading: false });
  };

  handleSubmit = () => {
    let { record } = this.state;
    let { rows } = this.state;
    let data = {}
    let mainData = this.state.data;
    if (document.querySelector('.submit-btn').textContent == "Update") {
      this.onSubmit().then(i => {
        document.querySelectorAll('.form1').forEach(i => {
          // console.log(i);
          if (i.type == "file") {
            data["Profile"] = this.state.fileUrl == null ? record.data["Profile"] : this.state.fileUrl;
          } else {
            // console.log(i.value);
            data[i.nextElementSibling.textContent] = i.value;
          }
        })
        // data["Activated"] = "false";

        let empObj = { data: data, field: "Master/Employee", id: record.id };
        console.log(empObj);
        this.setState({ open: false })
        if (data.Password == "") {
          this.setState({ open: true })
          if (!this.state.validatedIn) {
            return;
          }
        }
        else {
          this.setState({ passwordon: false })
          if (record.data.Password == data.Password) {
            this.setState({ isLoading: true });
            console.log(record);
            console.log(empObj);

            
            // db.collection("mess").doc(record.id).update(empObj).then(i => {
            //   window.location.reload(false);
            //   this.setState({ isLoading: false });
            // });

            const docId = record.key; // Assuming data["key"] contains the key of the node you want to update

            // Get a reference to the node you want to update
            const nodeRef = db.ref("User/" + docId);
            
            // Define the updates you want to apply
            
            // Update the node with the defined updates
            nodeRef.update(empObj)
              .then(() => {
                console.log("Node successfully updated!");
                window.location.reload(false); // Reload the page or update UI as needed
              })
              .catch((error) => {
                console.error("Error updating node: ", error);
              });
            




                      }
          else {
            this.setState({ passwordon: true })
          }
        }

      });
      return
    }
    // console.log(this.setState({ open: false }));
    // this.setState({ openon: false })
    this.setState({ open: false });

    if (!this.state.validatedIn) {
      // i.classList.remove('is-invalid')

      this.setState({ open: true })
      // this.setState({ openon: true })
      return;
    }
    //   let data = {}
    this.onSubmit().then(i => {
      document.querySelectorAll('.form1').forEach(i => {
        if (i.type == "file") {
          data["Profile"] = this.state.fileUrl;
        } else {
          data[i.nextElementSibling.textContent] = i.value;
        }
      });
      data["Activated"] = false;
      let empObj = { data: data, field: "Master/Employee", id: "emp" + uuid() };
      this.setState({ open: false })
      console.log(data);
      if (data.Password == "" || data.Address == "") {
        this.setState({ open: true })
        if (!this.state.validatedIn) {
          this.setState({ open: true })
          return;

        }
      }
      else {
        console.log(empObj);

        const ref = db.ref('User');

        // Example: Add data to the 'User' node
        ref.push(empObj)
          .then(() => {


            window.location.reload(false);

            this.setState({ isLoading: false });
          })
          .catch((error) => {
            console.error("Error adding data: ", error);
          });
        // let existingData = localStorage.getItem('myData');
        // if (existingData) {
        //     existingData = JSON.parse(existingData);
        // } else {
        //     existingData = [];
        // }

        // // Add new data to existing data
        // existingData.push(empObj);

        // // Save combined data back to localStorage
        // localStorage.setItem('myData', JSON.stringify(existingData));
        // this.downloadJsonFile(existingData, 'myData.json');




        // this.setState({ isLoading: true });
        // db.collection("mess").doc(empObj.id).set(empObj).then(i => {
        //   window.location.reload(false);
        //   this.setState({ isLoading: false });
        // });
      }
    });
  }


  downloadJsonFile = (data, filename) => {
    try {
      // Convert data to JSON string
      const jsonData = JSON.stringify(data);

      // Log the JSON data to verify its contents
      console.log('JSON Data:', jsonData);

      // Create a Blob from the JSON data
      const blob = new Blob([jsonData], { type: 'application/json' });

      // Log Blob information
      console.log('Blob:', blob);

      // Create a URL for the Blob
      const url = URL.createObjectURL(blob);

      // Log the URL for debugging
      console.log('URL:', url);

      // Create a temporary anchor element
      const a = document.createElement('a');
      a.href = url;
      a.download = filename || 'myData.json';

      // Log anchor element information
      console.log('Anchor Element:', a);

      // Programmatically trigger a click on the anchor element
      document.body.appendChild(a);
      a.click();

      // Clean up by removing the anchor element and revoking the object URL
      document.body.removeChild(a);
      URL.revokeObjectURL(url);

      console.log('Download initiated successfully');
    } catch (error) {
      console.error('Error creating or triggering download:', error);
    }
  };



  handleEdit = (e) => {
    let record = this.state.data[e.target.id];
    let { vals } = this.state;
    Object.keys(vals).forEach(i => {
      vals[i] = record.data[i];
    });
    document.querySelectorAll('.form1').forEach(i => {
      if (i.type == "file") {
        this.state.fileUrl = vals.Profile;
      }
    })


    document.querySelector('.submit-btn').textContent = 'Update';
    this.setState({ vals, record })
  }

  handleDelete = (e) => {
    // db.collection("mess").doc(this.state.data[e.target.id].id).delete().then(() => {
    //   window.location.reload(false);
    // })
    const docId = this.state.data[e.target.id].key; // Assuming data["key"] contains the key of the node you want to delete
 

    // Get a reference to the node you want to delete
    const nodeRef = db.ref("User/" + docId);

    // Remove the node
    nodeRef.remove()
      .then(() => {
        window.location.reload(false);
        // console.log("Node successfully deleted!");
      })
      .catch((error) => {
        // console.error("Error deleting node: ", error);
      });
  }

  empActivate = (e) => {
    let record = this.state.data[e.target.id];
    e.target.textContent = e.target.textContent == "Activate" ? "Deactivate" : "Activate";
    let emp = e.target.textContent;

    if (emp == "Activate") {
      record.data.Activated = false;
    }
    else {
      record.data.Activated = true;
    }
    db.collection("mess").doc(this.state.data[e.target.id].id).update(record).then(() => {

    })

  }

  getData = (data) => {
    let { rows } = this.state;
    data.forEach(i => {
      if (i.field == "Master/Employee")
        rows.push(i)
    })
    data = rows;
    let a = rows.map((i, k) => {
      return <tr>
        {["Sr", "Empolyee Name", "Mobile Number", "Email Id", "Address", "Aadhar Number", "Pan Number"].map((j) => {
          if (j == "Sr")
            return <td>{k + 1}</td>
          // if (j == "Activated") {
          //   return <td><Button className={`${k + 1}`} id={k} onClick={this.empActivate}>{i.data[j] ? "Deactivate" : "Activate"}</Button></td>
          // }

          if (j == "Profile")
            return <td><img style={{ height: "50px", width: "50px" }} src={i.data[j]}></img></td>
          return <td>{i.data[j]}</td>
        })}
        <td component="th" scope="row"><Button id={k} onClick={this.handleEdit}>edit</Button></td>
        <td component="th" scope="row"><Button id={k} onClick={this.handleDelete}>delete</Button></td>
      </tr>
    })
    this.setState({ rows: a, data })
  }

  updateData = () => { }

  removeData = () => { }

  handleInput = (e) => {
    let { vals } = this.state;
    let field = e.target.nextElementSibling.textContent
    vals[field] = e.target.value;
    this.validateInput(e)
    // console.log(this.state.validatedIn)
    // if(field == "Aadhar Number"){
    //     if(e.target.value.length != 12){
    //         if(!e.target.classList.contains('is-invalid'))
    //             e.target.classList.add('is-invalid')
    //     }else{
    //         if(e.target.classList.contains('is-invalid'))
    //             e.target.classList.remove('is-invalid')
    //     }
    // }
    // if(field == "Pan Number" || field == "Mobile Number"){
    //     if(e.target.value.length != 10){
    //         if(!e.target.classList.contains('is-invalid'))
    //             e.target.classList.add('is-invalid')
    //     }else{
    //         if(e.target.classList.contains('is-invalid'))
    //             e.target.classList.remove('is-invalid')
    //     }
    // }
    this.setState({ vals })
  }



  validateInput = (e) => {
    let field = e.target.nextElementSibling.textContent
    // props.validateInput(field,e.target.value)
    let value = e.target.value
    let ipfield;
    document.querySelectorAll('label').forEach(i => {
      if (i.textContent == field) {
        ipfield = i.previousElementSibling;
      }
    })

    let emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

    let tenDs = ["Mobile Number", "Phone Number", "Pan Number", "Bank Phon Number", "Bank Contact Person Number", "Bank Manager Number", "Customer Number", "Contact No."]
    let tweDs = ["Aadhar Number"]
    let mails = ["Personal Email", "Bank Contact Person Email Id", "Bank Manager Email Id", "Email Id"]
    let elevn = ["IFSC Code"];
    let alphabetic = ["Empolyee Name"];

    if (tweDs.indexOf(field) != -1) {
      if (value.length != 12) {
        if (!ipfield.classList.contains('is-invalid'))
          ipfield.classList.add('is-invalid')
      } else {
        if (ipfield.classList.contains('is-invalid'))
          ipfield.classList.remove('is-invalid')
      }
    }
    if (tenDs.indexOf(field) != -1) {
      if (value.length != 10) {
        if (!ipfield.classList.contains('is-invalid'))
          ipfield.classList.add('is-invalid')
      } else {
        if (ipfield.classList.contains('is-invalid')) {
          ipfield.classList.remove('is-invalid')
        }
      }
    }

    if (mails.indexOf(field) != -1) {
      let emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        if (!ipfield.classList.contains('is-invalid')) {
          ipfield.classList.add('is-invalid')
        }
      } else {
        if (ipfield.classList.contains('is-invalid')) {
          ipfield.classList.remove('is-invalid')
        }
      }
    }

    if (alphabetic.indexOf(field) != -1) {
      let emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      if (!/^[a-zA-Z\s]+$/.test(value)) {
        if (!ipfield.classList.contains('is-invalid')) {
          ipfield.classList.add('is-invalid')
        }
      } else {
        if (ipfield.classList.contains('is-invalid')) {
          ipfield.classList.remove('is-invalid')
        }
      }
    }



    if (elevn.indexOf(field) != -1) {
      if (value.length != 11) {
        if (!ipfield.classList.contains('is-invalid'))
          ipfield.classList.add('is-invalid')
      } else {
        if (ipfield.classList.contains('is-invalid')) {
          ipfield.classList.remove('is-invalid')
        }
      }

    }
    let flag = 1;
    document.querySelectorAll('.required').forEach(i => {
      if (i.value == "") {
        flag = 0
      }
    })
    document.querySelectorAll('.form1').forEach(i => {
      if (i.classList.contains('is-invalid')) {
        flag = 0
      }
    })


    this.setState({ validatedIn: !!flag })
    // return;
  }
  handleClose = () => {
    this.setState({ open: false });
  }
  // handleCloses = () => {
  //   this.setState({ openon: false});
  // }
  handleClosed = () => {
    this.setState({ passwordon: false });
  }

  render() {

    return (
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-lg-4 col-mg-4' style={{ borderTop: "2px solid", borderLeft: "2px solid", borderRight: "2px solid", height: "80vh", overflow: "auto" }}>
            <DataFetech isTemplate={false} getData={this.getData} ref={this.child} />

            <div className="d-flex flex-column container-fluid" style={{ width: "350px" }}>
              <div>
                <MDBValidation>
                  <span className='text-center'>
                    <h1 className='mt-3'>Employee</h1>
                  </span>
                  <p></p>
                  {/* {this.state.inputs} */}
                  <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title" style={{ color: "red" }}>
                      {"* All Mandatory Fields Are Required"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {/* {" Record Added Successfully "} */}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.handleClose} autoFocus>
                        Ok
                      </Button>
                    </DialogActions>
                  </Dialog>

                  {/* <Dialog
            open={this.state.openon}
            onClose={this.handleCloses}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
            {"All Fields Are Mandatory"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {" Record Added Successfully "}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloses} autoFocus>
                Ok
              </Button>
            </DialogActions>
          </Dialog>*/}
                  <Dialog
                    open={this.state.passwordon}
                    onClose={this.handleClosed}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title" style={{ color: "red" }}>
                      {"* Password Is Incorrect"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {/* {" Record Added Successfully "} */}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.handleClosed} autoFocus>
                        Ok
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <div>

                    <MDBValidationItem style={{ width: "100%" }} className='col-md-6' feedback='Please provide a valid Empolyee Name.' invalid>
                      <MDBInput className="form1" wrapperClass='mb-4' min="0" label='Empolyee Name' type="text" onChange={this.handleInput} value={this.state.vals['Empolyee Name']}></MDBInput>
                    </MDBValidationItem>
                    <MDBInput className="form1" wrapperClass='mb-4' min="0" label='Mobile Number' type="number" onChange={this.handleInput} value={this.state.vals['Mobile Number']}></MDBInput>
                    <div class="invalid-feedback">Please provide a valid aadhar number.</div>
                    <MDBValidationItem style={{ width: "100%" }} className='col-md-6' feedback='Please provide a valid email.' invalid>
                      <MDBInput className='form1' wrapperClass='mb-4' min="0" label='Email Id' type="mail" onChange={this.handleInput} value={this.state.vals['Email Id']}></MDBInput>
                    </MDBValidationItem>

                    <MDBInput className='form1' wrapperClass='mb-4' min="0" label='Address' type="text" onChange={this.handleInput} value={this.state.vals['Address']}></MDBInput>
                    <MDBValidationItem style={{ width: "100%" }} className='col-md-6' feedback='Please provide a valid aadhar number.' invalid>
                      <MDBInput required className='form1' wrapperClass='mb-4' min="0" label='Aadhar Number' type="number" onChange={this.handleInput} value={this.state.vals['Aadhar Number']}></MDBInput>
                    </MDBValidationItem>
                    {/* {this.state.vals['Pan Number'] != "123" ?  */}
                    <MDBValidationItem style={{ width: "100%" }} className='col-md-6' feedback='Please provide a valid pan number.' invalid>
                      <MDBInput required formNoValidate invalid className='form1' wrapperClass='mb-4' min="0" label='Pan Number' type="text" onChange={this.handleInput} value={this.state.vals['Pan Number']}></MDBInput>
                    </MDBValidationItem>
                    {/* :  */}
                    {/* <MDBInput required className='form1' wrapperClass='mb-4' min="0" label='Pan Number' type="number" onChange={this.handleInput} value={this.state.vals['Pan Number']}></MDBInput> */}
                    {/* } */}
                    {/* <p></p>
                    <label>Profile : </label>
                    <input label="Profile" className='form1' type="file" onChange={this.onFileChange} id="editImg" />
                    <div className='text-end'>
                    <img src={this.state.fileUrl} style={{ height: "50px", width: "50px" }}></img>
                    </div>
                    {this.state.isLoading && <LoaderComp />}

                    <p></p> */}
                    <MDBInput className='form1' wrapperClass='mb-4' min="0" label='Password' type="password" onChange={this.handleInput}></MDBInput>
                    <p></p></div>

                  <Button className='submit-btn' onClick={this.handleSubmit} style={{ backgroundColor: "#35424e" }}>Submit</Button>
                </MDBValidation>
              </div>
              <p></p>
            </div>
          </div>
          <div className='col-lg-8 col-mg-8' style={{ borderTop: "2px solid", borderLeft: "2px solid", borderRight: "2px solid" }}>
            <h1 className='text-center mt-3'>Data Of Employee</h1>
            <Table striped bordered hover responsive border={"1px solid black"} className='mt-5'>
              <thead>
                <tr>
                  <th>Sr. </th>
                  <th>Employee Name</th>
                  <th>Mobile Number</th>
                  <th>Email Id</th>
                  <th>Address</th>
                  <th>Aadhar Number</th>
                  <th>Pan Number</th>
                  <th>Action(Edit)</th>
                  <th>Action(Delete)</th>
                </tr>
              </thead>
              <tbody>
                {/* {this.state.rows} */}
                {this.state.rows.map(i => i)}
              </tbody>
            </Table>

          </div></div>
      </div>
    )
  }

}
