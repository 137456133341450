import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import BasicMenu from './BasicMenu';
import "./forms/app.css"
import { Button } from 'react-bootstrap';


export default function MenuAppBar(props) {

  let currentPage = window.location.pathname.split("/");
  const currentURL = window.location.href;

  const isPropertyPage = currentURL.includes('Group');
  const isOffice = currentURL.includes('Office');
  // Check if "vehicle" is present in the URL
  const isVehiclePage = currentURL.includes('Resi');

  const isMasterpage = currentURL.includes('Master');
  const isEmployee = currentURL.includes('Employee');



  currentPage.forEach((i, j) => {
    if (i.indexOf("%20") > -1)
      currentPage[j] = i.split("%20").join(" ");
  });
  // }


  // const [auth, setAuth] = React.useState(true);
  // const [anchorEl, setAnchorEl] = React.useState(null);

  // const handleChange = (event) => {
  //   setAuth(event.target.checked);
  // };

  // const handleMenu = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  // let submenu = (menu_data[props.text].map(i => <MenuItem className='abc'  onClick={() => handleMenu(i)}>{i}</MenuItem>))

  //  let sub_menu =  menu_data[`${currentPage[2]}`].map(i => ( <li 
  //  onClick={() => {
  //       if(i == "Bank"){
  //         console.log(i)
  //         window.location.href = `/Table/Master/Bank`
  //       }
  //       else if(i == "Bank Info"){
  //         window.location.href = `/Table/Master/Bank%20Info`
  //       }
  //       else if(i == "New Intimation"){
  //         window.location.href = `/Table/Vehicle%20Valuation/New%20Intimation`
  //       }else if(i =="")
  // //   // console.log(i)
  // //       if (i == "Schedule"){
  // //         if (i == "Vehicle Valuation")
  // //           window.location.pathname = "Vehicle Valuation/Schedule";
  // //         else
  // //           window.location.pathname = "Property Valuation/Schedule";
  // //       }
  // //       else {
  // //         if (i == "Employee")
  // //           window.location.pathname = "/Employee";
  // //         else
  // //         // console.log(props.page !== "report" ? `/Table/` + props.text + '/' + i : `/${i}`)
  // //           window.location.pathname = i !== "report" ? `/Table/` + i + '/' + i : `/${i}`;
  // //       }
  //  }}

  //  >{i}{console.log(i)}</li>));

  // const handleMenu = (i) => {
  //   console.log(i);
  //     if (i == "Schedule")
  //       if (props.text == "Vehicle Valuation")
  //         window.location.pathname = "Vehicle Valuation/Schedule";
  //       else
  //         window.location.pathname = "Property Valuation/Schedule";
  //     else {
  //       if (i == "Employee")
  //         window.location.pathname = "/Employee";
  //       else
  //         window.location.pathname = props.page !== "report" ? `/Table/` + props.text + '/' + i : `/${i}`;
  //     }
  // }


  const dashBoard = () => {
    window.location.pathname = "/"
  }




  return (
    <Box >
      {/* <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={auth}
              onChange={handleChange}
              aria-label="login switch"
            />
          }
          label={auth ? 'Logout' : 'Login'}
        />
      </FormGroup> */}

      <AppBar position="static">



        <div className='toolbar' style={{backgroundColor:"#35424e"}}>

          <Button onClick={dashBoard} style={{ marginLeft: "10px", marginBottom: "0.4%", background: "#35424e", fontSize: "15px" ,color: "#fff"}}>Dashboard</Button>
          <h5 style={{ "textAlign": "center" }}><BasicMenu userID={props.userID} className="0 menu-item" text="Master" /></h5>
          <h5 style={{ "textAlign": "center" }}><BasicMenu userID={props.userID} className="1 menu-item" text="Resi Vist" /></h5>
          <h5 style={{ "textAlign": "center" }}><BasicMenu userID={props.userID} className="2 menu-item" text="JLG Group" /></h5>
          <h5 style={{"textAlign": "center"}}><BasicMenu userID={props.userID} className="3 menu-item" text="Office Visit"/></h5>
          {/* <h5 style={{"textAlign": "center"}}> */}

          {/* </h5> */}
          {/* <h5 style={{"textAlign": "center"}}><div userID={props.userID} className="3 menu-item" text="Dashboard"/></h5> */}
          {/* <h5 style={{"textAlign": "center"}}><BasicMenu userID={props.userID} page={'report'} className="4 menu-item" text="Dashboard"/></h5> */}
          {/* <h5 style={{"textAlign": "center"}}><BasicMenu userID={props.userID} page={'report'} className="4 menu-item" text="Report"/></h5> */}
          {
            props.userID === "true" ? <Button onClick={() => props.logout()} style={{ marginLeft: "700px", marginBottom: "0.4%", background: "#35424e", fontSize: "15px" }}>Log out</Button> : ""
          }

        </div>


      </AppBar>

      <div className='abc'>
        {/* <h3>{arr1}</h3> */}
        {/* {arr1.map((i => <li >{i}</li>))} */}
        {/* {sub_menu} */}
        {/* {<h2>hellow</h2>}    */}

        <div>
          {isMasterpage && (
            <div className='xyz'>
              <ul className='subMenu'>
                <li><a href='/Table/Master/Bank'>Bank</a></li>
                {/* <li><a href='/Table/Master/Bank%20Info'>Bank Info</a></li> */}
                <li><a href='/Employee'>Employee</a></li>
                {/* <li><a href='/Table/Master/Bank/Type'>Vehicle Type</a></li> */}
                {/* <li><a href='/Table/Master/Bank/Model'>Make/Model</a></li> */}
              </ul>
            </div>
          )}

          {isEmployee && (
            <div>
              <ul className='subMenu'>
                <li><a href='/Table/Master/Bank'>Bank</a></li>
                {/* <li><a href='/Table/Master/Bank%20Info'>Bank Info</a></li> */}
                <li><a href='/Employee'>Employee</a></li>
                {/* <li><a href='/Table/Master/Bank/Type'>Vehicle Type</a></li> */}
                {/* <li><a href='/Table/Master/Bank/Model'>Make/Model</a></li> */}
              </ul>
            </div>
          )}
          {isPropertyPage && (
            <div>
              <ul className='subMenu'>
                
              <li><a href="/Table/JLG%20Group/Intimation">Group Intimation</a></li>
                <li><a href="/Table/JLG%20Group/Mis">Mis</a></li>
              </ul>
            </div>
          )}

{isOffice && (
            <div>
              <ul className='subMenu'>
              <li><a href="/Table/Resi%20Vist/Intimation">Resi Intimation</a></li>
                <li><a href="/Table/Resi%20Vist/Mis">Mis</a></li>
              </ul>
            </div>
          )}
          {isVehiclePage && (
            <div>
              <ul className='subMenu'>
              <li><a href="/Table/Office%20Visit/Intimation">Office Intimation</a></li>
                <li><a href="/Table/Office%20Visit/Mis">Mis</a></li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </Box>
    //{auth && (
    //<div>
    /* <IconButton
      size="large"
      aria-label="account of current user"
      aria-controls="menu-appbar"
      aria-haspopup="true"
      onClick={handleMenu}
      color="inherit"
    >
      <AccountCircle />
    </IconButton> */
    /* <Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem onClick={handleClose}>Profile</MenuItem>
      <MenuItem onClick={handleClose}>My account</MenuItem>
    </Menu> */
    //</div>
    //)}

  );
}