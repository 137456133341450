import { MDBContainer } from 'mdb-react-ui-kit'
import React, { Component, useState } from 'react'
import { Button, Table } from 'react-bootstrap'
import DataFetech from './dataFetech';
import ReportTableComp from './ReportTableComp';
import TemplateFetch from './TemplateFetch';
import { db } from './firebase'
import LoaderComp from './LoaderComp'; // Import your Loader component


export default class ReportTable extends Component{
    constructor(props){
        super(props)
        this.state = {data : [],isLoading : false, tablecomp : <ReportTableComp handleDelete={this.handleDelete} data={[]}/>};
        this.child = React.createRef();

    }
    
    updateTable = () => {
        setTimeout(() => {
          this.child.current.fetchAll();
        }, 500)
      }

    handleEdit = (i) => {
        let {data} = this.state;
        this.setState({ isLoading: true });
        this.props.editData(data[i])
        this.setState({ isLoading: false });
    }

    handleDelete = (i) => {
        let {data} = this.state;
        console.log(data[i]);
        this.setState({ isLoading: true });
        db.collection("templates").doc(data[i].id).delete().then(i => {
            this.updateTable();
            this.setState({ isLoading: false });
        })

    }

    getTemplateData = (data) => {
        let dataa = [];
        data.forEach(i => {
            // console.log(i.category,window.location.pathname.split("/")[2].split("%20").join(" "),i.category == window.location.pathname.split("%20").join(" "));
            if(i.category == window.location.pathname.split("/")[2].split("%20").join(" "))
                dataa.push(i.name)
        })
        console.log({dataa});
        setTimeout(() => {
            this.setState({ isLoading: true });
            this.setState({tablecomp : <ReportTableComp handleDelete={this.handleDelete} handleEdit={this.handleEdit} updateTable={this.updateTable} data={dataa}/>, data:data})
            this.setState({ isLoading: false });
        }, 500)
    }

    render(){
        return (
        <div className='col-md-8 col-lg-8' style={{borderTop: "2px solid" ,borderLeft: "2px solid", borderRight: "2px solid", height: "80vh" ,overflow: "auto"}}>
        <TemplateFetch isTemplate={true} getTemplateData={this.getTemplateData} ref={this.child}/>
        <div>{this.state.isLoading && <LoaderComp />}</div>
        {this.state.tablecomp}
        {/* <Button onClick={this.handleEdit}>Click</Button> */}
    </div>
  )
}
}