import React, { useState,useRef, Component, useEffect } from 'react';
import {
  MDBContainer,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBCheckbox
}
from 'mdb-react-ui-kit';
import {firestore} from './firebase';
import {addDoc, collection} from '@firebase/firestore';
import { useParams } from 'react-router';
import DataFetech from './dataFetech';
import { db } from './firebase'


function User(props) {
  const emailRef = useRef();
  const passwordRef = useRef();

  const handleSave = async (e) => {
    e.preventDefault();
    let mail = emailRef.current.value;
    let pass = passwordRef.current.value;
    // if(mail == "test@gmail.com" && pass == "123456"
    //   ){
    //   localStorage.setItem("loginToken",JSON.stringify("true"));
    //   localStorage.setItem("loginId",JSON.stringify(mail));
    //   props.changeAuth("user1");
    // }
    // console.log(pass,mail);

    // const ref = db.ref("https://surveryv2-default-rtdb.firebaseio.com/");

    // Using once() method to retrieve data once
    const dbRef = db.ref(); // This could be the root reference or any other specific path in your database

dbRef.once("value")
  .then((snapshot) => {
    // snapshot.val() contains the retrieved data
    const data = snapshot.val();
    const values = Object.values(data);
    // console.log("Retrieved data:", values);
    values.map((i)=>{
      // console.log(Object.values(i));
      Object.values(i).map((i)=>{
        // console.log(i["field"]);
        if(i["field"] == "Master/Employee"){
          // console.log(i.data["Empolyee Name"]);
            if(i.data["Email Id"] == mail){
              if(i.data["Password"] == pass){
                window.location.pathname = "/Table/JLG/New%20Intimation";
              }else{
                console.log("wroge pass");
              }
            }else{
              console.log("invide email");
            }
        }
      })
    })
  })
  .catch((error) => {
    console.error("Error retrieving data:", error);
  });
  }



  return (
    <MDBContainer className="p-3 my-5 d-flex flex-column w-50">
     <h1>User Login</h1>
          <MDBInput wrapperClass='mb-4' label='Email Address' id='form1' type='email' ref={emailRef}/>
          <MDBInput wrapperClass='mb-4' label='Password' id='form2' type='password' ref={passwordRef}/>

          <MDBBtn className="mb-4 w-100" onClick={handleSave} style={{backgroundColor:"#35424e"}}>Log in</MDBBtn>
    </MDBContainer>
  );
}

export default User;
