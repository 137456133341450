import { MDBContainer } from 'mdb-react-ui-kit';
import React, { Component } from 'react'
import { Button } from 'react-bootstrap';
import DataFetech from './dataFetech'
import exportFunc from './Excelexport';
import DropDown from './forms/DropDown';
import TemplateFetch from './TemplateFetch';
import Editor from './Editor';
import Editors from "./Editors"
import { db } from "./firebase";
import LoaderComp from './LoaderComp'; // Import your Loader component

export default class PropertyGenerateReport extends Component{
    constructor(props){
        super(props);
        this.currentDate = new Date();
        this.state = {templates : [], form : "Master/Bank", data : [], wordComp : "",  wordComps: "", dispalyOn: "none" ,isLoading : false}
        this.getData = this.getData.bind(this);
    }
    
    getData = (i) => {
        this.setState({mainData : i});
    }

    getTemplateData = (i) => {
        let arr = [];
    i.forEach(j => {
      if(j.category == window.location.pathname.split("/")[2].split("%20").join(" "))
        arr.push(j.name)
    });
        this.setState({templates : arr, data : i});
    }

    export = () => {
        let {form} = this.state;
        
        let format = "";
        let newData = [];
        let newData1 =[];
        let newData2 =[];
        let newData3 =[];
        let formm;
        this.state.data.forEach(i => {
            if(i.name == document.querySelector(".templateName").value){
                // console.log(i);
                formm = i;
                form = i.category;
                format = i.data;
            }
        })
        
        
        this.state.mainData.forEach(i => {
           
            let obj = {};
            let e = document.querySelector(".totalSurvey")
                    var text = e.options[e.selectedIndex].text;
                    if (i.data == undefined) {
                        return null
                    } else {
                    if(i.data["Ref. No."] == text){

            if(i.field.indexOf(form) != -1){
                if(format){
                    Object.keys(format).forEach((j,k) => {
                        obj[j] = i.data[format[j]]
                    })
                }
                newData.push(obj);
            }
        }
    }
        });


        this.state.mainData.forEach(i => {
           
            let obj2 = {};
            let e = document.querySelector(".alltotalSurvey")
                    var text = e.options[e.selectedIndex].text;
                    // console.log(text);
                    if (i.data == undefined) {
                        return null
                    } else {
                    if(i.field == "Property Valuation/New Intimation"){
                        if(i.field.indexOf(form) != -1){
                            if(format){
                                Object.keys(format).forEach((j,k) => {
                                    obj2[j] = i.data[format[j]]
                                })
                            }
                            newData1.push(obj2);
                        }
        }
    }
        });
        this.state.mainData.forEach(i => {
            let obj3 = {};
            let e = document.querySelector(".alltotalSurvey")
                    var text = e.options[e.selectedIndex].text;
                    if (i.data == undefined) {
                        return null
                    } else {
                        
                    if(i.field == "Property Valuation/New Intimation"){
                     
                        const month = String(this.currentDate.getMonth() + 1).padStart(2, '0');
                        const day = String(this.currentDate.getDate()).padStart(2, '0');
                        const year = this.currentDate.getFullYear();
                        // console.log(`${day}/${month}/${year}`,i.data["date"]);
                        if(i.data["date"] == `${day}/${month}/${year}`)
                        {
                            // console.log(i);
                            if(i.field.indexOf(form) != -1){
                                if(format){
                                    Object.keys(format).forEach((j,k) => {
                                        obj3[j] = i.data[format[j]]
                                    })
                                }
                                newData2.push(obj3);
                            }
                        }            
        }
    }
        });
        this.state.mainData.forEach(i => {
            let obj4 = {};
            let e = document.querySelector(".alltotalSurvey")
                    var text = e.options[e.selectedIndex].text;
                    if (i.data == undefined) {
                        return null
                    } else {
                        
                    if(i.field == "Property Valuation/New Intimation"){
                     
                        const month = String(this.currentDate.getMonth() + 1).padStart(2, '0');
                        const day = String(this.currentDate.getDate()).padStart(2, '0');
                        const year = this.currentDate.getFullYear();
                        // console.log(`${day}/${month}/${year}`,i.data["date"]);
        
                        if(i.data["date"] == `${day - 1}/${month}/${year}`)
                        {
                            // console.log(i);
                            if(i.field.indexOf(form) != -1){
                                if(format){
                                    Object.keys(format).forEach((j,k) => {
                                        obj4[j] = i.data[format[j]]
                                    })
                                }
                                newData3.push(obj4);
                            }
                        }            
        }
    }
        });
        
        let ckdit = "";
        this.state.data.map((k) => {
            if (k.format === "Word" && k.category === "Property Valuation") {
                // Assuming 'editor' is your CKEditor instance
                let ab = document.querySelector(".templateName").value

                if (k.name == ab) {
                    // console.log(k);
                    ckdit = k.updateData;
                    this.setState({ wordComp: <Editor data={ckdit} /> });
                }

                // ckdit = k.updateData;
                // this.setState({ wordComp: <Editor data={ckdit} /> });
            }
        });
            
        let keys = Object.keys(newData[0])

        newData.forEach(i => {
            ckdit += `<p>`
            // keys.forEach(j => {
                // ckdit += `My ${j} is ${i[j]} <br>`
            // })
            ckdit += `</p>`
        })


        if(formm.format == "Word"){
            this.setState({ wordComp: <Editor data={ckdit} /> });
            this.setState({ dispalyOn: true });
            var element = document.getElementsByClassName('exportBtn');
            element[0].style.display = '';
            var element = document.getElementsByClassName('exportGenerate');
            element[0].style.display = '';
        }
        else{
            let e = document.querySelector(".alltotalSurvey")
                    let text = e.options[e.selectedIndex].text;
                    console.log(text);
            if(text == "All"){
                exportFunc(newData1) 
            }else if(text == "Today data"){
                
                exportFunc(newData2)
            }
            else if(text == "select the option"){
                let a = [newData[0]]
                exportFunc(a) 
            }
            else if(text == "Yesterday data"){
                exportFunc(newData3)
            }

        }    
    }
    update = () => {
        // console.log(this.state.wordComp.props)
        // console.log(this.state.wordComp.props.data)

        let d = this.state.wordComp.props;

        this.state.data.map((k) => {
            //     // console.log(k.format);

            if (k.format == "Word" && k.category == "Property Valuation") {
               

                const elements = document.getElementsByClassName("ck ck-content ck-editor__editable ck-rounded-corners ck-editor__editable_inline ck-blurred");

                // Check if there are elements with the specified class
                if (elements.length > 0) {
                    // Access the first element (you can loop through elements if needed)
                    const divContent = elements[0].innerHTML;
                    // console.log(divContent);

                    // Regular expression to match {{Variable}}
                    const variableRegex = /{{([^}]+)}}/g;

                    // Create an array to store the variables and their corresponding div content
                    const variableList = [];

                    // Use a loop to find and store all variables
                    let match;
                    let arr = [];
                    while ((match = variableRegex.exec(divContent)) !== null) {
                        const variable = match[1]; // Extract the variable between {{ }}
                        const contentWithVariable = divContent.replace(match[0], ''); // Remove the variable from content
                        variableList.push({ variable });
                        // console.log(variable + ":" + variable)
                        arr.push(variable);
                        // db.collection("templates").doc(k.id).update({data2: variable });
                    }
                    const obj = {};

                    // Iterate over the inputArray and assign each element as both key and value in the object
                    for (const item of arr) {
                        obj[item] = item;
                    }


                    let ab = document.querySelector(".templateName").value
                    let arr3 = [];
                    if (ab == k.name) {
                        console.log(obj);
                        this.setState({ isLoading: true });
                        db.collection("templates").doc(k.id).update({ data2: obj }).then(() => {
                            this.setState({ isLoading: false });
                            // window.location.reload(false);
                          });
                        let e = document.querySelector(".totalSurvey")
                        var text = e.options[e.selectedIndex].text;
                        //  console.log(k);
                        this.state.mainData.map((i) => {
                            // console.log(i.field);
                            if (i.field == "Property Valuation/New Intimation") {
                                // console.log(i.data["Ref. No."])
                                if (i.data["Ref. No."] == text) {
                                    // console.log(k.id);
                                    arr3.push(text, divContent)
                                    //  console.log(arr3);
                                    // db.collection("templates").doc(k.id).update(arr3);
                                    // console.log(arr3);
                                }
                            }
                        })
                        this.setState({ isLoading: true });
                        db.collection("templates").doc(k.id).update({ updateData: divContent }).then(() => {
                            this.setState({ isLoading: false });
                            window.location.reload(false);
                          });
                    }


                } else {
                    console.log("No elements found with the specified class.");
                }


            }
        });
    };

    abc = (e) => {
        let obj = {};
        let arr = [];
        this.state.data.map((i) => {
            {
                /* console.log(i) */
            }
            if (i.category == "Property Valuation") {
                if (i.format == "Word") {
                    // console.log(i.name)
                    if (i.name == e.target.value) {
                            // console.log(i.data);

                        if (i.data == undefined) {
                            return null;
                        }
                        Object.values(i.data).forEach((j, k) => {
                            // console.log(j);
                            arr.push(j);

                        });
                        const myDiv = document.getElementById("imgVecal");
                        myDiv.innerHTML = JSON.stringify(arr);
                    }
                }
            }
        });

        this.state.data.map((i) => {
            if (i.category == "Property Valuation") {
                if(e.target.value == i.name){
                    
                    const myDiv = document.getElementById("showFormate");
                        myDiv.innerHTML = i.format;
                }
            }
        })

        
    }

    Generate = () => {
        this.state.data.map((i) => {
            if (i.category == "Property Valuation") {
                if (i.format == "Word") {
                    let ab = document.querySelector(".templateName").value
                    if (ab == i.name) {
                        // console.log(i.updateData);

                        let { form } = this.state;
                        let format = "";
                        let newData = [];
                        let newImg = [];
                        let formm;
                        this.state.data.forEach((i) => {
                            if (i.name == document.querySelector(".templateName").value) {
                                formm = i;
                                form = i.category;
                                format = i.data2;
                                console.log(format);
                            }
                        });

                        this.state.mainData.forEach((i) => {
                            let obj = {};
                            let e = document.querySelector(".totalSurvey")
                            var text = e.options[e.selectedIndex].text;
                            if(i.data == undefined){
                                return null
                            }else{

                            
                            if (i.data["Ref. No."] == text) {
                                if (i.field.indexOf(form) != -1) {
                                    if (format) {
                                        
                                        Object.keys(format).forEach((j, k) => {
                                            if(j == "LocationGraph"){
                                                if(i.images == undefined){
                                                    obj[j] = undefined;
                                                }else{
                                                i.images.map((p)=>{
                                                    if(Object.keys(p) == "LocationGraph"){
                                                        // console.log(Object.values(p));
                                                        // valy = 'img src="' + Object.values(p) + '"'
                                                        // obj[j] = valy;
                                                        let valy = '<img src="' + Object.values(p) + '">'
                                                        obj[j] = valy;
                                                    }
                                                })
                                            }
                                            }else if(j == "engineer"){
                                                if(i.images == undefined){
                                                    obj[j] = undefined;
                                                }else{

                                
                                                i.images.map((p)=>{
                                                    if(Object.keys(p) == "engineer"){
                                                        let valy = '<img src="' + Object.values(p) + '">'
                                                        obj[j] = valy;
                                                    }
                                                })}
                                            }else if(j == "GoogleMaps"){
                                                if(i.images == undefined){
                                                    obj[j] = undefined;
                                                }else{

                                                
                                                i.images.map((p)=>{
                                                    if(Object.keys(p) == "GoogleMaps"){
                                                        let valy = '<img src="' + Object.values(p) + '">'
                                                        obj[j] = valy;
                                                    }
                                                })
                                            }
                                            }else if(j == "streetview"){
                                                if(i.images == undefined){
                                                    obj[j] = undefined;
                                                }else{

                                                
                                                i.images.map((p)=>{
                                                    if(Object.keys(p) == "streetview"){
                                                        let valy = '<img src="' + Object.values(p) + '">'
                                                        obj[j] = valy;
                                                    }
                                                })}
                                            }else if(j == "person"){
                                                if(i.images == undefined){
                                                    obj[j] = undefined;
                                                }else{
                                                i.images.map((p)=>{
                                                    if(Object.keys(p) == "person"){
                                                        let valy = '<img src="' + Object.values(p) + '">'
                                                        obj[j] = valy;
                                                    }
                                                })
                                            }
                                            }else if(j == "maindoor"){
                                                if(i.images == undefined){
                                                    obj[j] = undefined;
                                                }else{

                                            
                                                i.images.map((p)=>{
                                                    if(Object.keys(p) == "maindoor"){
                                                        let valy = '<img src="' + Object.values(p) + '">'
                                                        obj[j] = valy;
                                                    }
                                                })}
                                            }else if(j == "frontview"){
                                                if(i.images == undefined){
                                                    obj[j] = undefined;
                                                }else{
                                                i.images.map((p)=>{
                                                    if(Object.keys(p) == "frontview"){
                                                        let valy = '<img src="' + Object.values(p) + '">'
                                                        obj[j] = valy;
                                                    }
                                                })}
                                            }else if(j == "leftsideview"){
                                                if(i.images == undefined){
                                                    obj[j] = undefined;
                                                }else{

                                                
                                                i.images.map((p)=>{
                                                    if(Object.keys(p) == "leftsideview"){
                                                        let valy = '<img src="' + Object.values(p) + '">'
                                                        obj[j] = valy;
                                                    }
                                                })}
                                            }else if(j == "rightside"){
                                                if(i.images == undefined){
                                                    obj[j] = undefined;
                                                }else{
                                                i.images.map((p)=>{
                                                    if(Object.keys(p) == "rightside"){
                                                        let valy = '<img src="' + Object.values(p) + '">'
                                                        obj[j] = valy;
                                                    }
                                                })}
                                            }else if(j == "frontroad"){
                                                if(i.images == undefined){
                                                    obj[j] = undefined;
                                                }else{


                                                i.images.map((p)=>{
                                                    if(Object.keys(p) == "frontroad"){
                                                        let valy = '<img src="' + Object.values(p) + '">'
                                                        obj[j] = valy;
                                                    }
                                                })}
                                            
                                            }else if(j == "externalpic1"){
                                                if(i.images == undefined){
                                                    obj[j] = undefined;
                                                }else{
                                                i.images.map((p)=>{
                                                    if(Object.keys(p) == "externalpic1"){
                                                        let valy = '<img src="' + Object.values(p) + '">'
                                                        obj[j] = valy;
                                                    }
                                                })}
                                            }else if(j == "externalpic2"){
                                                if(i.images == undefined){
                                                    obj[j] = undefined;
                                                }else{
                                                i.images.map((p)=>{
                                                    if(Object.keys(p) == "externalpic2"){
                                                        let valy = '<img src="' + Object.values(p) + '">'
                                                        obj[j] = valy;
                                                    }
                                                })}
                                            }else if(j == "internalpic1l"){
                                                if(i.images == undefined){
                                                    obj[j] = undefined;
                                                }else{
                                                i.images.map((p)=>{
                                                    if(Object.keys(p) == "internalpic1l"){
                                                        let valy = '<img src="' + Object.values(p) + '">'
                                                        obj[j] = valy;
                                                    }
                                                })}
                                            }else if(j == "internalpic2k"){
                                                if(i.images == undefined){
                                                    obj[j] = undefined;
                                                }else{
                                                i.images.map((p)=>{
                                                    if(Object.keys(p) == "internalpic2k"){
                                                        let valy = '<img src="' + Object.values(p) + '">'
                                                        obj[j] = valy;
                                                    }
                                                })}
                                            }else if(j == "internalpic3b"){
                                                if(i.images == undefined){
                                                    obj[j] = undefined;
                                                }else{
                                                    i.images.map((p)=>{
                                                        if(Object.keys(p) == "internalpic3b"){
                                                            let valy = '<img src="' + Object.values(p) + '">'
                                                            obj[j] = valy;
                                                        }
                                                    })
                                                }
                                                
                                            }else if(j == "internalpic2"){
                                                if(i.images == undefined){
                                                    obj[j] = undefined;
                                                }else{
                                                i.images.map((p)=>{
                                                    if(Object.keys(p) == "internalpic2"){
                                                        let valy = '<img src="' + Object.values(p) + '">'
                                                        obj[j] = valy;
                                                    }
                                                })}
                                            }else if(j == "internalpic3"){
                                                if(i.images == undefined){
                                                    obj[j] = undefined;
                                                }else{
                                                i.images.map((p)=>{
                                                    if(Object.keys(p) == "internalpic3"){
                                                        let valy = '<img src="' + Object.values(p) + '">'
                                                        obj[j] = valy;
                                                    }
                                                })}
                                            }else if(j == "internalpic1"){
                                                if(i.images == undefined){
                                                    obj[j] = undefined;
                                                }else{
                                                i.images.map((p)=>{
                                                    if(Object.keys(p) == "internalpic1"){
                                                        let valy = '<img src="' + Object.values(p) + '">'
                                                        obj[j] = valy;
                                                    }
                                                })}
                                            }else if(j == "internalpic4"){
                                                if(i.images == undefined){
                                                    obj[j] = undefined;
                                                }else{
                                                i.images.map((p)=>{
                                                    if(Object.keys(p) == "internalpic4"){;
                                                        let valy = '<img src="' + Object.values(p) + '">'
                                                        obj[j] = valy;
                                                    }
                                                })}
                                            }else if(j == "supportdocs"){
                                                if(i.images == undefined){
                                                    obj[j] = undefined;
                                                }else{
                                                i.images.map((p)=>{
                                                    if(Object.keys(p) == "supportdocs"){
                                                        let valy = '<img src="' + Object.values(p) + '">'
                                                        obj[j] = valy;
                                                    }
                                                })}
                                            }
                    
                                            
                                            else{
                                                obj[j] = i.data[format[j]];
                                                // console.log(format[j]);
                                            }
                                            
                                        });
                                    }
                                    newData.push(obj);
                                }
                            }
                        }
                        });
                        console.log(newData);
                        // Replace placeholders in i.updateData
                        // console.log(i.updateData);
                        let updatedData = i.updateData;
                        Object.keys(newData[0]).forEach((key) => {
                            const placeholder = `{{${key.trim()}}}`; // Trim the key to remove any leading/trailing whitespace
                            const value = newData[0][key];
                            updatedData = updatedData.replace(new RegExp(placeholder, 'g'), value);
                        });
                        // const myDiv = document.getElementById("dataGenerate");
                        // myDiv.innerHTML = updatedData;
                        // console.log(updatedData);
                        this.setState({ wordComps: <Editors data={updatedData} /> });
                    }
                }
            }
        });
    }

    render(){
        return (
            <div className='container-fluid'>
            <div className='row'>
              <div className='' style={{}}>
                <div className="d-flex flex-column " style={{ width: "100%" }}>
        <DataFetech isTrue={false} getData={this.getData}/>
        <TemplateFetch isTemplate={true} getTemplateData={this.getTemplateData}/>
        <div></div>
        <h1>Generate Report</h1>
        <p></p>
        <h6>Report Account</h6>
        <h6>Format : <span id ="showFormate"> </span></h6>
        <select className='templateName' onChange={this.abc}>
          {this.state.templates.map(i => {
              return <option>{i}</option>
            })}
        </select>
        <p></p>
        <h6>Ref. No. Of Total Intimations</h6>
        {/* <p className="totalSurvey" id="totalSur">All</p> */}
                    <select className="totalSurvey mb-3" id="totalSur"></select>
                    <span style={{ display: "none" }}>
                        {setTimeout(() => {
                            let ar = [];
                            let arr1 = [];
                            let arr2 = [];
                            if (this.state.mainData == undefined) {
                                return null;
                            }
                            this.state.mainData.map((i) => {
                                {/* console.log(i.field) */}
                                if (i.field == "Property Valuation/New Intimation") {
                                    {
                                        /* console.log(i.data) */
                                    }
                                    ar.push(i.data);
                                }
                            });
                            {
                                /* console.log(ar) */
                            }
                            ar.map((k) => {
                                {
                                    /* console.log(k); */
                                }
                                arr1.push(k);
                            });
                            {
                                /* console.log(arr1) */
                            }

                            arr1.map((k) => {
                                {
                                    /* console.log(k["Ref. No."]) */
                                }
                                arr2.push(k["Ref. No."]);
                            });

                            { console.log(arr2); }
                            const select = document.getElementById("totalSur");
                            select.innerHTML = '';
                            for (const [index, a] of arr2.entries()) {
                                const opt = document.createElement("option");
                                opt.value = index;
                                opt.innerHTML = a;
                                select.appendChild(opt);
                            }
                            {/* console.log(arr2); */}
                        }, 0)}
                    </span>
                    <h6>Export data (only for Excel)</h6>
                    <select className="alltotalSurvey" id="allltotalSur">
                    <option select value="option">select the option</option>
                        <option value="All">All</option>
                        <option value="today">Today data</option>
                        <option value="Yesterday">Yesterday data</option>
                    </select>
                    <p></p>
                   <div style={{textAlign:"center"}}>
                   <Button onClick={this.export}>Export</Button>
                </div> 
        
        <div id="imgVecal"></div>
        <p></p>
        
       
                                            {this.state.wordComp}
                    <p></p>
                    <div id="dataGenerate"></div>
                    <div style={{textAlign:"center"}}>
                    <Button onClick={this.update} style={{ "display": "none" }} className="exportBtn">Update</Button>
                    </div>
                    </div>

        </div>
                    <div className='' >
            <div className="d-flex flex-column " style={{ width: "100%" }}>
            <h1>View Generate Report</h1>
                    <p></p>
                    {this.state.wordComps}
                    <p></p>
                    <div style={{textAlign:"center"}}>
                    <Button onClick={this.Generate} style={{ "display": "none" }} className="exportGenerate">Generate</Button>
                    </div>
                    <p></p>


                
                <div>{this.state.isLoading && <LoaderComp />}</div>
                </div>
                    </div>

    </div>
    </div>
  )
}
}
































// import { MDBAccordion, MDBBtn, MDBContainer, MDBInput } from 'mdb-react-ui-kit'
// import React, { Children, useState } from 'react'
// import { Button } from 'react-bootstrap'
// import DropdownContext from 'react-bootstrap/esm/DropdownContext'
// import DropDown from './forms/DropDown'
// import { form_data, menu_data } from './Menu-data'
// import "./report.css"
// import './form.css'
// import exportFunc from './Excelexport'
// import DataFetech from './dataFetech'
// import { ThirtyFpsSelect } from '@mui/icons-material'

// export default function Report() {
//     const [optValue, setOptValue] = useState("Master")
//     const [userData, setUserData] = useState({})
//     const [formVals, setFormVals] = useState(["Bank", "Bank Info", "Employee"]);
//     const [form, setForm] = useState("Master/Bank");
//     const [rowNum, setRowNum] = useState(0);
//     const [rowArr, setRowArr] = useState([]);

//     const handleSave = (e) => {
//         let newData = [];
//         let exportData = [];
//         for(let i = 0; i < userData.length; i++) {
//             if(userData[i].field == form){
//                 userData[i]["data"]["id"] = userData[i].id; 
//                 newData.push(userData[i].data)
//             }
//         }

//         exportData = newData.map(i => {
//             return {id : i.id}
//         })

//         let newObj;
//         document.querySelectorAll(".field").forEach(i => {
//             newObj = {};
//             newData.forEach((j,k) => {
//                 if(exportData[k].id == j.id)
//                     exportData[k][i.children[1].value] = j[i.children[0].value]
//             })
//         })

//         exportData.forEach(i => delete i.id);
//         exportFunc(exportData);
//     }

//     const getData = (data) => {
//         setUserData(data);
//     }

//     const handleOption = (label,value) => {
//         console.log(label,value);
//         optValue[label] = value;
//         setOptValue(optValue);
//     }

//     // let dropdowns = 


//     const formChanged = (e) => {
//         console.log(form_data[e.target.value]);
//         setFormVals(Object.keys(form_data[e.target.value]));
//     }

//     const categoryChanged = (e) => {
//         setForm(document.querySelector(".opt1").value+"/"+document.querySelector(".opt2").value);
//     }
//     console.log(rowArr);
//   return (
//       <MDBContainer className="p-3 my-5 d-flex flex-column w-50">
//         <DataFetech getData={getData}/>
//         <h1>Report</h1>
//         <select className=''>
//             <option>Excel</option>
//             <option>Word</option>
//         </select>
//         <p></p>
//         <select className='opt1' onChange={formChanged}>
//             <option>Master</option>
//             <option>Vehicle Valuation</option>
//             <option>Property Valuation</option>
//         </select>
//         <p></p>
//         <select className="opt2" onChange={categoryChanged}>
//             {formVals.map(i => {
//                 return <option>{i}</option>
//             })}
//         </select>
//         <p></p>
//         <MDBInput wrapperClass='mb-4' label={'No. of Rows'} value={rowNum} onChange={(e) => {
//             setRowNum(e.target.value)
//             }} className='form1' type={'text'}/>
//         <Button onClick={() => {
//             for(let i = 0; i < rowNum; i++){
//                 rowArr[i] = 0
//             }
//             console.log(rowArr);
//             // setRowArr(rowArr);
//             setRowNum(rowNum)
//         }}>Go</Button>
//         <p></p>
//         <div className='dropdowns'>

//             {console.log(rowArr)}{
//             rowArr.map(i => {
//         return(
//             <div className={`${i} field`}>
//         <select>
//             {(form_data[form.split("/")[0]][form.split("/")[1]]).map(i => {       
//                 return <option>{i.title}</option>
//             }
//             )
//         }
//         </select>
        
//         <input style={{marginLeft : "30%", marginBottom: "5%"}} type={'text'}></input>
//         </div>
//             )
//     })}
//         </div>
//         <MDBBtn key={"mb-4 w-100"} className="mb-4 w-100" onClick={handleSave}>Save</MDBBtn>
//     </MDBContainer>
//   )
// }


// {/* <div className='container'>
//         <h1>Report</h1>
//         <div style={{width : "30%", height: "5px"}}>
//         </div>
//         <select value={opt} onChange={(e) => setOpt(e.target.value)}>
//             <option>Master</option>
//             <option>Vehicle Valuation</option>
//             <option>Property Valuation</option>
//             <option>Billing</option>
//         </select>

//         {(menu_data[opt]).map(i => {
//             // {if(!checkedVals[i]) checkedVals[i] = false}
//             return <><input type="checkbox" className='report' id={i.toLowerCase().split(" ").join("-")} name={i} value={i} onChange={handleCheckbox}/>{i}</>
//         })}

//         <p></p>
//         <Button>Generate</Button>        
//         </div> */}